import { PaymentMethod } from '@stripe/stripe-js';
import React, { useState, useEffect } from 'react';
import { Dimmer, Divider, Grid, Loader } from 'semantic-ui-react';
import { useAlertServiceContext } from '../components/RiskAlertContext/RiskAlertContext';
import { SubscriptionSummary } from '../components/Subscriptions/SubscriptionSummary';
import ChangeEmail from '../components/UserProfile/ChangeEmail';
import ChangePassword from '../components/UserProfile/ChangePassword';
import ChangePhoneNumber from '../components/UserProfile/ChangePhoneNumber';
import { sendAccountSettingsUpdatedEmailAsync } from '../services/Email/AccountUpdatedEmailSender';

const UserProfileDashboard: React.FunctionComponent = () => {
  const { userStore } = useAlertServiceContext();
  const [isLoading, setIsLoading] = useState(false);

  const user = userStore.user;

  useEffect(() => {
    if (!userStore.user?.paymentMethodLastFour) {
      userStore.loadUser();
    }
  });

  const saveUpdate = async (paymentMethod: PaymentMethod) => {
    setIsLoading(true);
    if (!user?.customerId) {
      await userStore.createCustomer();
    }

    if (user?.customerId) {
      await userStore.updatePaymentMethod(paymentMethod.id, user.customerId);
    }

    await sendAccountSettingsUpdatedEmailAsync(
      'The payment method on your account has been changed.',
    );

    setIsLoading(false);
  };

  return (
    <Dimmer.Dimmable
      dimmed={isLoading}
      style={{
        margin: '1rem',
      }}
    >
      <Dimmer inverted active={isLoading}>
        <Loader />
      </Dimmer>
      <Grid stackable style={{ padding: ' 2rem', marginBottom: '2rem' }}>
        <Grid.Row centered={true}>
          <Grid.Column width={6}>
            <Divider />
            <ChangeEmail />
            <Divider />
            <ChangePassword />
            <Divider />
            <ChangePhoneNumber />
          </Grid.Column>
          <Grid.Column width={8}>
            <Divider />
            <SubscriptionSummary
              onSaveHandler={async (paymentMethod: PaymentMethod) => {
                await saveUpdate(paymentMethod);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Dimmer.Dimmable>
  );
};

export default UserProfileDashboard;
