import React, { useState } from 'react';
import { Search } from 'semantic-ui-react';
import { ISearchedItem } from '../../services/Stock/IStockService';
import { useDebounce } from '../Hooks/useDebounce';
import { SearchResultRow } from './SearchResultRow';

interface ISymbolSearchProps {
  isDisabled?: boolean;
  onSelected: (selectedSearchResult: ISearchedItem) => void;
  onSearch: (searchTerm: string) => Promise<ISearchedItem[]>;
  searchDebounceTime?: number;
}

export const SymbolSearch: React.FC<ISymbolSearchProps> = (
  props: ISymbolSearchProps,
) => {
  const { isDisabled, onSelected, onSearch, searchDebounceTime } = props;

  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const [loading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<ISearchedItem[]>([]);

  useDebounce(
    async () => {
      if (searchTerm) {
        setIsLoading(true);
        try {
          const searchResult = await onSearch(searchTerm);

          if (searchResult) {
            const mappedToDescription = searchResult.map((item: any) => {
              return {
                ...item,
                description: item.name,
                id: 1 - Math.random(),
                title: item.symbol,
                price: '',
              };
            });
            setSearchResults(mappedToDescription);
            return;
          }
        } catch (ex) {
          console.error(ex);
        } finally {
          setIsLoading(false);
        }
      }

      setSearchResults([]);
    },
    searchDebounceTime || 500,
    [searchTerm],
  );

  return (
    <Search
      disabled={isDisabled || false}
      style={{ borderRadius: '0px' }}
      input={{
        input: {
          'data-testid': 'symbolSearchInput',
        },
        fluid: true,
        style: { borderRadius: '0px' },
      }}
      fluid
      aligned={'left'}
      loading={loading}
      onResultSelect={(e, data) => {
        onSelected(data.result);
        setSearchTerm('');
      }}
      onSearchChange={(e, data) => {
        setSearchTerm(data.value);
      }}
      showNoResults={false}
      resultRenderer={SearchResultRow}
      results={searchResults}
      value={searchTerm}
    />
  );
};
