export class UserConfig {
  private _sharesToBuy = 0;
  private _risePercentage = 0.1;
  private _fallPercentage = 0.1;
  private _startingPrice = 0;
  private _beginningValue = 0;

  constructor(
    sharesToBuy: number,
    risePercentage: number,
    fallPercentage: number,
    startingPrice: number,
    beginningValue: number,
  ) {
    this._sharesToBuy = sharesToBuy;
    this._risePercentage = risePercentage;
    this._fallPercentage = fallPercentage;
    this._startingPrice = startingPrice;
    this._beginningValue = beginningValue;
  }

  public GetSharesToBuy = (): number => {
    return this._sharesToBuy;
  };

  public GetRisePercentage = (): number => {
    return this._risePercentage;
  };

  public GetFallPercentage = (): number => {
    return this._fallPercentage;
  };

  public GetStartingPrice = (): number => {
    return this._startingPrice;
  };

  public GetBeginningValue = (): number => {
    return this._beginningValue;
  };
}
