import React from 'react';
import RiskHeader from './RiskAlertHeader.module.css';
import { Header, Image } from 'semantic-ui-react';
import logo from '../../logo.png';

const LoginHeader: React.FunctionComponent = () => {
  return (
    <>
      <header className={RiskHeader.header}>
        <Image
          src={logo}
          style={{
            margin: 'auto',
            height: '50px',
          }}
        />
        <Header
          style={{
            fontFamily: "'Lato' 'Helvetica Neue' 'sans-serif'",
            margin: 'auto',
            height: 'auto',
          }}
          textAlign="center"
          as="h1"
          inverted
        ></Header>
        <ul
          className={RiskHeader.ul}
          style={{
            margin: 'auto',
            height: '50px',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <li>
            {' '}
            <a
              className={RiskHeader.tags}
              target="#"
              href="https://myriskalert.com/"
            >
              About
            </a>
          </li>
          <li>
            <a
              className={RiskHeader.tags}
              target="#"
              href="https://myriskalert.com/faqs/"
            >
              FAQs
            </a>
          </li>
          <li>
            <a
              className={RiskHeader.tags}
              target="#"
              href="https://myriskalert.com/pricing/"
            >
              Pricing
            </a>
          </li>
          <li>
            <a
              className={RiskHeader.tags}
              target="#"
              href="https://myriskalert.com/contact/"
            >
              Contact
            </a>
          </li>
        </ul>
      </header>
    </>
  );
};

export default LoginHeader;
