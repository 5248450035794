import React from 'react';
import { observer } from 'mobx-react-lite';
import { CreateSubscription } from '../CreateSubscription/CreateSubscription';
import RiskAlertHeader from '../Layout/RiskAlertHeader';
import { Grid } from 'semantic-ui-react';
import { useAlertServiceContext } from '../RiskAlertContext/RiskAlertContext';
import { Redirect } from 'react-router-dom';

const CollectInitialPaymentInfo: React.FC = () => {
  const { userStore } = useAlertServiceContext();

  const currentUser = userStore.user;

  return (
    <>
      <RiskAlertHeader />
      {currentUser?.subscriptionId && <Redirect to="/dashboard" />}
      <Grid stackable style={{ margin: '0 auto 0 auto', maxWidth: '750px' }}>
        <Grid.Column verticalAlign="middle">
          <Grid.Row>
            <CreateSubscription />
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default observer(CollectInitialPaymentInfo);
