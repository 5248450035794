import {
  DailyInfoResponse,
  StockData,
} from '../../domain/interfaces/Models/DailyInfoResponse';
import { FormattedDailyData } from '../../domain/interfaces/Models/FormattedDailyData';
import { ISearchedItem, IStockService } from './IStockService';

const error = new Error('Something went wrong');

export class StockService implements IStockService {
  private apiKey: string;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  public async search(searchTerm: string): Promise<ISearchedItem[]> {
    const response = await fetch(
      `https://eodhistoricaldata.com/api/search/${searchTerm}?api_token=${this.apiKey}&limit=15`,
    );
    const result = await response.json();
    return result.map((item: any) => {
      return {
        name: item.Name,
        symbol: `${item.Code}.${item.Exchange}`,
        market: item.Exchange,
      };
    });
  }

  public async getPastYearForChart(
    symbol: string,
  ): Promise<FormattedDailyData[]> {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    const today = new Date();
    const dateFrom = oneYearAgo.toISOString().split('T')[0];
    const dateTo = today.toISOString().split('T')[0];
    const response = await fetch(
      `https://eodhistoricaldata.com/api/eod/${symbol}?from=${dateFrom}&to=${dateTo}&api_token=${this.apiKey}&order=d&fmt=json`,
    );

    if (response.status !== 200) {
      throw error;
    }

    const splitResponse = await fetch(
      `https://eodhistoricaldata.com/api/splits/${symbol}?from=${dateFrom}&to=${dateTo}&api_token=${this.apiKey}&order=d&fmt=json`,
    );

    const splitData = await this.EODSplitResponseToSplit(splitResponse);

    const data: DailyInfoResponse = await this.EodResponseToDailyInfo(
      response,
      symbol,
      splitData,
    );

    return this.formatYearlyData(data);
  }

  private EODSplitResponseToSplit = async (
    response: Response,
  ): Promise<any[]> => {
    const data = await response.json();

    return data.map((d: any) => {
      const split = d.split.split('/');
      return {
        date: d.date,
        ratio: split[0] / split[1],
      };
    });
  };

  private EodResponseToDailyInfo = async (
    response: Response,
    symbol: string,
    splitData: any[],
  ) => {
    const dailyData = await response.json();
    let splitAdjustment = 1;
    const formattedData = [];

    for (let i = 0; i < dailyData.length; i++) {
      const data = dailyData[i];

      formattedData.push({
        close: data.close,
        symbol: symbol,
        date: new Date(data.date),
        exchange: data.exchage,
        name: data.name,
        volume: data.volume,
        splitAdjustment: splitAdjustment,
        adjusted_close: data.close / splitAdjustment,
      });

      const foundSplit = splitData.find((split) => split.date === data.date);

      if (foundSplit) {
        splitAdjustment *= foundSplit.ratio;
      }
    }

    return { data: formattedData };
  };

  private async EODAggResponseToDailyInfo(response: Response, symbol: string) {
    const data = await response.json();

    const stockData: StockData[] = data.map((r: any) => ({
      volume: r.volume,
      open: r.open,
      close: r.close,
      low: r.low,
      high: r.high,
      symbol: symbol,
      date: new Date(r.date),
      adjusted_close: r.adjusted_close,
    }));

    return { data: stockData };
  }

  public formatYearlyData(result: DailyInfoResponse): FormattedDailyData[] {
    const formattedData: FormattedDailyData[] = [];
    const dailyData = result.data;

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    for (let i = dailyData.length - 1; i >= 0; i--) {
      const data = dailyData[i];

      formattedData.unshift({
        closePrice: data.close.toString(),
        date: data.date.toISOString().split('T')[0],
        splitAdjustedClosePrice: data.adjusted_close.toString(),
      });
    }

    return formattedData;
  }
}
