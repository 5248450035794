/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import logo from '../../logo.png';
import { Colors } from '../CompareAlert/Colors';

import {
  Image,
  Container,
  Grid,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';
import NavigationMenu from './NavigationMenu';
import RiskAlertHeader from './RiskAlertHeader';
import NavigationMenuForMobile from './NavigationMenuForMobile';

export const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 705,
    computer: 1024,
  },
});

class DesktopContainer extends Component<{ title: string }> {
  state: any = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children, title } = this.props;

    return (
      <>
        <Media greaterThan="mobile" className="mediaContainer">
          <Visibility
            once={false}
            onBottomPassed={this.showFixedMenu}
            onBottomPassedReverse={this.hideFixedMenu}
          >
            <Grid>
              <Grid.Row>
                <RiskAlertHeader title={title} />
                <Grid.Column width={2}>
                  <NavigationMenu />
                </Grid.Column>
                <Grid.Column width={14}>{children}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Visibility>
        </Media>
      </>
    );
  }
}

class MobileContainer extends Component {
  state: { sidebarOpened: boolean } = { sidebarOpened: false };

  handleSidebarHide = (event: any) => {
    const path = event.composedPath() as any[];

    const menuButtonFound = path.find((item) => item.id === 'menu-button');
    if (!menuButtonFound) {
      this.setState({ sidebarOpened: false });
    }
  };

  showSideBar = () => {
    this.setState({ sidebarOpened: true });
  };

  static propTypes: {
    children: PropTypes.Requireable<PropTypes.ReactNodeLike>;
  };

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Media at="mobile">
        {(_, show) =>
          show && (
            <div style={{ height: '100%' }}>
              <Sidebar.Pushable>
                <Sidebar
                  as={Menu}
                  animation="overlay"
                  inverted
                  onHide={this.handleSidebarHide}
                  vertical
                  visible={sidebarOpened}
                >
                  <NavigationMenuForMobile />
                </Sidebar>
                <Sidebar.Pusher dimmed={sidebarOpened}>
                  <Segment
                    inverted
                    textAlign="center"
                    style={{
                      backgroundColor: Colors.darkGreen,
                      height: '90px',
                      padding: '1em 0em',
                      borderBottom: `3px solid ${Colors.orange}`,
                    }}
                    vertical
                  >
                    <Container>
                      <Menu inverted secondary size="large">
                        <Menu.Item
                          onClick={this.showSideBar}
                          icon="sidebar"
                          id="menu-button"
                        />
                        <Menu.Item position="right">
                          <Image
                            src={logo}
                            style={{ margin: 'auto', height: '30px' }}
                          />
                        </Menu.Item>
                      </Menu>
                    </Container>
                  </Segment>
                  <Grid>
                    <Grid.Row centered={true}>{children}</Grid.Row>
                  </Grid>
                </Sidebar.Pusher>
              </Sidebar.Pushable>
            </div>
          )
        }
      </Media>
    );
  }
}

const ResponsiveContainer: React.FC<{ children: any; title: string }> = ({
  children,
  title,
}) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer title={title}>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

export default ResponsiveContainer;

export const withResponsiveContainer = (Component: any, title: string) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => (
    <ResponsiveContainer title={title}>
      <Component {...props} />
    </ResponsiveContainer>
  );
};
