import React, { useState } from 'react';
import {
  Button,
  Card,
  Container,
  Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Loader,
  Segment,
} from 'semantic-ui-react';
import { v4 } from 'uuid';
import { IAlert } from '../../stores/AlertStore';
import ErrorDisplay from '../Messaging/ErrorDisplay';

interface IAlertFormProps {
  inputAlert: IAlert;
  isLoading: boolean;
  onSaveClick: (savedAlert: IAlert) => Promise<void>;
  onCancelClick: () => void;
  cancelButtonLabel: string;
  alertLimitReached: boolean;
}

export const AlertForm: React.FC<IAlertFormProps> = (
  props: IAlertFormProps,
) => {
  const {
    inputAlert,
    onSaveClick,
    onCancelClick,
    cancelButtonLabel,
    isLoading,
    alertLimitReached,
  } = props;

  const [downswingPercent, setDownswingPercent] = useState<number>(
    inputAlert.downswingPercent || 10,
  );
  const [upswingPercent, setUpswingPercent] = useState<number>(
    inputAlert.upswingPercent || 10,
  );

  const [textAlert, setTextAlert] = useState(inputAlert.textAlerts);
  const [emailAlert, setEmailAlert] = useState(inputAlert.emailAlerts);

  const saveClickHandler = async () => {
    await onSaveClick({
      id: inputAlert.id || v4(),
      name: inputAlert.name,
      market: inputAlert.market,
      symbol: inputAlert.symbol,
      downswingPercent: downswingPercent,
      upswingPercent: upswingPercent,
      emailAlerts: emailAlert,
      textAlerts: textAlert,
      dateCreated: inputAlert.dateCreated || new Date().toUTCString(),
    });
  };

  const alertIsValid = () => {
    return upswingPercent > 0 && downswingPercent > 0;
  };

  return (
    <Segment secondary>
      <Dimmer active={isLoading}>
        <Loader data-testid="saveLoader" />
      </Dimmer>
      <Card.Header>
        <Header>Symbol: {inputAlert.symbol}</Header>
      </Card.Header>
      <Divider />
      <Form>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Input
                input={{
                  'data-testid': 'alertFormDownswing',
                }}
                type="text"
                icon="percent"
                value={downswingPercent}
                onChange={(_, data) => {
                  const percent = Number(data.value.replace(/\D/, ''));
                  if (percent >= 0 && percent < 9999) {
                    setDownswingPercent(Number(data.value.replace(/\D/, '')));
                  }
                }}
                label="RiskAlert %"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                input={{
                  'data-testid': 'alertFormUpswing',
                }}
                type="text"
                icon="percent"
                value={upswingPercent}
                onChange={(_, data) => {
                  const percent = Number(data.value.replace(/\D/, ''));
                  if (percent >= 0 && percent < 9999) {
                    setUpswingPercent(Number(data.value.replace(/\D/, '')));
                  }
                }}
                label="OpportunityAlert %"
              />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Checkbox
                toggle
                data-testid="alertFormEmailAlert"
                checked={emailAlert}
                onChange={(event, data) => {
                  setEmailAlert(data.checked || false);
                }}
                label="Email Alerts"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Checkbox
                toggle
                data-testid="alertFormTextAlert"
                checked={textAlert}
                onChange={(event, data) => {
                  setTextAlert(data.checked || false);
                }}
                label="Text Alerts"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Container textAlign="right">
              <Button
                primary
                onClick={saveClickHandler}
                disabled={!alertIsValid()}
              >
                Save
              </Button>
              <Button style={{ marginRight: '1em' }} onClick={onCancelClick}>
                {cancelButtonLabel}
              </Button>
            </Container>
          </Grid.Row>
          {alertLimitReached && (
            <Grid.Row>
              <Container textAlign="center">
                <ErrorDisplay message={'Max of 50 Risk Alerts Reached.'} />
              </Container>
            </Grid.Row>
          )}
        </Grid>
      </Form>
    </Segment>
  );
};
