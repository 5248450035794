/* eslint-disable @typescript-eslint/no-explicit-any */
export const SearchForStock: any = async (searchTerm: string) => {
  const stockApiBaseUrl = 'https://eodhistoricaldata.com/api/search/';
  const apiKey = process.env.REACT_APP_STOCK_API_KEY;

  const error = new Error('Something went wrong');

  const response = await fetch(
    `${stockApiBaseUrl}${searchTerm}?api_token=${apiKey}&limit=15`,
  );

  if (response.status == 200) {
    const result = await response.json();

    return result.map((item: any) => {
      return {
        name: item.Name,
        symbol: `${item.Code}.${item.Exchange}`,
        market: item.Exchange,
      };
    });
  } else {
    throw error;
  }
};
