import React, { ChangeEvent, useState } from 'react';
import { Colors } from '../Colors';
import { FormProps } from '../Domain/Interfaces/FormProps';
import { FormatPrice } from '../Functions/FormatPrice';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { FormHeader } from '../SimulatorDashboardComponents/FormHeader';

const re = /^[0-9\b]+$/;

// eslint-disable-next-line max-lines-per-function
export const Form: React.FC<FormProps> = ({
  submitHandler,
  selectedSymbol,
  engineResults,
  shouldSeeForm,
  setRiseAlert,
  setFallAlert,
  resetClickHandler,
  riseAlert,
  fallAlert,
  setStartDate,
  startDate,
  beginningValue,
  setBeginningValue,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [stockQuantity, setStockQuantity] = useState(100);

  const formSubmit = async () => {
    await submitHandler(stockQuantity);
  };

  const alertChange = (
    event: ChangeEvent<HTMLInputElement>,
    method: (value: number) => void,
  ) => {
    const element = event.target as HTMLInputElement;
    const number = element.value.replace('%', '');
    if (number === '' || re.test(number)) {
      if (+number >= 0 && +number > 9999) {
        return;
      }
      method(+number / 100);
    }
  };

  const onDateChange = (e: any, d: any) => {
    setStartDate(d.value);
  };

  const disabled =
    stockQuantity <= 0 ||
    riseAlert < 0.01 ||
    riseAlert > 0.99 ||
    fallAlert < 0.01 ||
    fallAlert > 0.99 ||
    beginningValue <= 0;

  if (shouldSeeForm && !engineResults && selectedSymbol) {
    return (
      <>
        <FormHeader
          selectedSymbol={selectedSymbol}
          resetClickHandler={resetClickHandler}
        />
        <div
          className="ui inverted segment"
          style={{ maxWidth: 590, backgroundColor: Colors.darkGreen }}
        >
          <div className="ui inverted form">
            <div className="field">
              <label style={{ textAlign: 'left' }}>
                Your Investment Symbol:
              </label>
              <input
                className="ui disabled input"
                type="text"
                data-testid={'stock-symbol-input'}
                value={selectedSymbol}
                readOnly
              />
            </div>
            <div className="inline field">
              <div className="field">
                <label
                  style={{
                    textAlign: 'left',
                  }}
                >
                  Enter Total Dollar Cost For These Shares:
                </label>
                <input
                  className="ui disabled"
                  type="text"
                  data-testid={'beginning-value-input'}
                  value={`${FormatPrice(+beginningValue.toFixed(2))}`}
                  onChange={(e) => {
                    setBeginningValue(
                      +e.target.value
                        .replace('$', '')
                        .replaceAll(',', '')
                        .replace(/\D/, ''),
                    );
                  }}
                />
              </div>
            </div>
            <div className="inline field">
              <div className="field">
                <label style={{ width: '40%', textAlign: 'left' }}>
                  Enter Current # of Shares:
                </label>
                <input
                  type="text"
                  data-testid={'share-quantity-input'}
                  value={stockQuantity}
                  onChange={(e: ChangeEvent<HTMLElement>) => {
                    const element = e.target as HTMLInputElement;
                    setStockQuantity(+element.value.replace(/\D/, ''));
                  }}
                />
              </div>
            </div>

            <div className="inline field">
              <div className="field">
                <label
                  style={{
                    width: '30%',
                    textAlign: 'left',
                  }}
                >
                  Risk Alert:
                </label>
                <input
                  className="ui"
                  type="text"
                  data-testid={'fall-alert-value-input'}
                  value={`%${(fallAlert * 100).toFixed()}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    alertChange(e, setFallAlert)
                  }
                />
              </div>
            </div>
            <div className="inline field">
              <div className="field">
                <label
                  style={{
                    width: '30%',
                    textAlign: 'left',
                  }}
                >
                  Opportunity Alert:
                </label>
                <input
                  className="ui"
                  type="text"
                  data-testid={'rise-alert-value-input'}
                  value={`%${(riseAlert * 100).toFixed()}`}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    alertChange(e, setRiseAlert)
                  }
                />
              </div>
            </div>
            <div className="field datepicker">
              <div className="field">
                <label
                  style={{
                    width: '30%',
                    textAlign: 'left',
                  }}
                >
                  Start Date:
                </label>
                <SemanticDatepicker
                  datePickerOnly
                  clearable={false}
                  format="MM-DD-YYYY"
                  maxDate={new Date()}
                  showToday={false}
                  value={startDate}
                  onChange={onDateChange}
                  className="picker"
                />
              </div>
            </div>
            <div>
              {!disabled || (
                <span
                  style={{
                    display: 'block',
                    float: 'left',
                    marginRight: '20px',
                    color: Colors.fallRed,
                    clear: 'left',
                    fontWeight: 600,
                  }}
                >
                  Please enter number of shares, cost of shares, and alerts
                  greater than 1% and less than 99%
                </span>
              )}
              <button
                className="ui submit button"
                style={{
                  backgroundColor: Colors.orange,
                  color: Colors.offWhite,
                }}
                onClick={async () => {
                  formSubmit();
                }}
                disabled={disabled}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
