import { Auth } from 'aws-amplify';
import { IEmailRequest } from './EmailRequest';
import { sendEmailAsync } from './EmailService';

export const sendAccountSettingsUpdatedEmailAsync = async (
  settingChangedMessage: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  retrievedCognitoUser?: any,
): Promise<void> => {
  const cognitoUser = !!retrievedCognitoUser
    ? retrievedCognitoUser
    : await Auth.currentAuthenticatedUser();

  const userEmail = cognitoUser?.attributes?.email;
  if (!!userEmail) {
    const accountSettingsUpdatedRequest = getAccountSettingsUpdatedEmailRequest(
      userEmail,
      settingChangedMessage,
    );
    await sendEmailAsync(accountSettingsUpdatedRequest);
  }
};

function getAccountSettingsUpdatedEmailRequest(
  emailAddress: string,
  settingChangedMessage: string,
): IEmailRequest {
  return {
    emailAddress: emailAddress,
    subject: 'RiskAlert Account Settings Updated',
    message: `${settingChangedMessage} If you did not make these changes, contact us immediately at info@myriskalert.com`,
  };
}
