import React, { createContext, useContext } from 'react';
import { CompareAlertViewModel } from '../../CompareAlertViewModel';
import { AlertService } from '../../services/Alert/AlertService';
import { StockService } from '../../services/Stock/StockService';
import { UserService } from '../../services/User/UserService';
import { AlertStore } from '../../stores/AlertStore';
import { UserStore } from '../../stores/UserStore';
import { IRiskAlertContext } from './IRiskAlertContext';

const createContextDependencies = (): IRiskAlertContext => {
  const alertService = new AlertService(
    process.env.REACT_APP_BACKEND_BASE_URL as string,
  );
  const userService = new UserService(
    process.env.REACT_APP_BACKEND_BASE_URL as string,
  );

  const alertStore = AlertStore.create({ isLoading: false }, { alertService });
  const userStore = UserStore.create({ isLoading: false }, { userService });

  const now = new Date();
  const yearPrior = new Date();
  yearPrior.setFullYear(now.getFullYear() - 1);

  const compareAlertViewModel = CompareAlertViewModel.create({
    showForm: true,
    selectedSymbol: '',
    historicalData: [],
    currentSharePrice: 0,
    engineResults: undefined,
    seeDetails: false,
    riseAlert: 0.1,
    fallAlert: 0.1,
    startDate: yearPrior,
    beginningValue: 0,
    isLoading: false,
  });

  const stockService = new StockService(
    process.env.REACT_APP_STOCK_API_KEY as string,
  );

  return {
    alertStore,
    stockService,
    userStore,
    compareAlertViewModel,
  };
};

export const contextDependencies = createContextDependencies();

export const AlertServiceContext = createContext(contextDependencies);

export const useAlertServiceContext = (): IRiskAlertContext => {
  return useContext(AlertServiceContext);
};

export const RiskAlertContext: React.FC = (props) => {
  return (
    <AlertServiceContext.Provider value={contextDependencies}>
      {props.children}
    </AlertServiceContext.Provider>
  );
};
