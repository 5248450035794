import React from 'react';
import { Colors } from '../Colors';
import { ResultsSummaryProps } from '../Domain/Interfaces/ResultsSummaryProps';
import { FormatPrice } from '../Functions/FormatPrice';
import { Grid, Header } from 'semantic-ui-react';

// eslint-disable-next-line max-lines-per-function
export const ResultsSummary: React.FC<ResultsSummaryProps> = ({
  canSeeDetails,
  engineResults,
  onButtonClick: onDetailsClick,
}) => {
  const onSeeDetailsClick = () => {
    onDetailsClick(!canSeeDetails);
  };

  const getColor = (value: number) => {
    return value > 0 ? Colors.riseGreen : Colors.fallRed;
  };

  const getValueDisplay = (value: number) => {
    return (
      <span
        style={{
          color: getColor(value),
          fontWeight: 800,
        }}
      >
        {FormatPrice(+value.toFixed(0))}{' '}
      </span>
    );
  };

  const getPercentDisplay = (value: number) => {
    return (
      <span
        style={{
          color: getColor(value),
          fontWeight: 800,
        }}
      >
        {+value.toFixed(0)}
        {'%'}
      </span>
    );
  };

  const getValueColumn = (value: number, mobileLabel: string) => {
    return (
      <>
        <Grid.Column only="mobile">
          {mobileLabel}
          <div style={{ float: 'right' }}>{getValueDisplay(value)}</div>
        </Grid.Column>
        <Grid.Column only="computer">{getValueDisplay(value)}</Grid.Column>
      </>
    );
  };

  const getPercentColumn = (value: number, mobileLabel: string) => {
    return (
      <>
        <Grid.Column only="mobile">
          {mobileLabel}
          <div style={{ float: 'right' }}>{getPercentDisplay(value)}</div>
        </Grid.Column>
        <Grid.Column only="computer">{getPercentDisplay(value)}</Grid.Column>
      </>
    );
  };

  if (engineResults) {
    const differnceIfSoldOnFirstAlert =
      engineResults.valueIfSoldOnFirstAlert - engineResults.currentValue;
    const percentageDiffernceIfSoldOnFirstAlert =
      (differnceIfSoldOnFirstAlert / engineResults.currentValue) * 100;
    const difference =
      engineResults.valueWithAlerts - engineResults.currentValue;
    const percentageDifference =
      (difference / engineResults.currentValue) * 100;
    return (
      <>
        <Grid.Row
          style={{
            justifyContent: 'left',
            width: '100vw',
            padding: '1rem',
          }}
        >
          <Grid.Column
            textAlign={'left'}
            style={{
              maxWidth: '800px',
              backgroundColor: Colors.darkGreen,
              borderRadius: 10,
              border: `solid 1px ${Colors.offWhite}`,
              color: Colors.offWhite,
              fontSize: 15,
              padding: 15,
            }}
          >
            <h1 className="ui row" style={{ color: Colors.orange }}>
              Summary
            </h1>
            <Header inverted>
              Past performance does not indicate future results.
            </Header>
            <Header inverted>Analysis*</Header>
            <Grid textAlign="left" columns={5} stackable>
              <Grid.Row columns={1}>
                <Grid.Column verticalAlign="bottom">
                  <Header as="h4" inverted>
                    If sold on first RiskAlert
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row only="computer">
                <Grid.Column>Value using RiskAlert</Grid.Column>
                <Grid.Column>Current Value as of Today</Grid.Column>
                <Grid.Column>Difference</Grid.Column>
                <Grid.Column>Percentage Difference</Grid.Column>
                <Grid.Column>Gain/(Loss) if taxable</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {getValueColumn(
                  engineResults.valueIfSoldOnFirstAlert,
                  'Value using RiskAlert',
                )}
                {getValueColumn(
                  engineResults.currentValue,
                  'Current Value as of Today',
                )}
                {getValueColumn(differnceIfSoldOnFirstAlert, 'Difference')}
                {getPercentColumn(
                  percentageDiffernceIfSoldOnFirstAlert,
                  'Percentage Difference',
                )}
                {getValueColumn(
                  engineResults.gainOrLossFirstTransaction,
                  'Gain/(Loss) if taxable',
                )}
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column verticalAlign="bottom">
                  <Header as="h4" inverted>
                    Value if RiskAlert Used as a Management Tool(Buy/Sell Upon
                    Each Alert)
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row only="computer">
                <Grid.Column>Value using RiskAlert</Grid.Column>
                <Grid.Column>Current Value as of Today</Grid.Column>
                <Grid.Column>Difference</Grid.Column>
                <Grid.Column>Percentage Difference</Grid.Column>
                <Grid.Column>Gain/(Loss) if taxable</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {getValueColumn(
                  engineResults.valueWithAlerts,
                  'Value using RiskAlert',
                )}
                {getValueColumn(
                  engineResults.currentValue,
                  'Current Value as of Today',
                )}
                {getValueColumn(difference, 'Difference')}
                {getPercentColumn(
                  percentageDifference,
                  'Percentage Difference',
                )}
                {getValueColumn(
                  engineResults.gainOrLoss,
                  'Gain/(Loss) if taxable',
                )}
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Header inverted>CompareAlert</Header>
                  <p style={{ fontSize: 'large' }}>
                    CompareAlert allows you to choose a start date and
                    calculates through today's date using end of day price.
                  </p>
                  <p style={{ fontSize: 'large' }}>
                    From the start date, if the price falls below its high value
                    by your risk percentage it calculates a RiskAlert (Red Dot).
                    It assumes you sell and then monitors to the low value. If
                    the price reaches your opportunity percentage above the low
                    value, it calculates an OpportunityAlert (Green Dot). It
                    assumes you buy and reinvest the amount from your last sale.
                  </p>
                  <p style={{ fontSize: 'large' }}>
                    IMPORTANT: CompareAlert calculates on end-of-day price,
                    while myRiskAlert monitors your stock price hourly from
                    which email/text alerts are sent. Thus, your actual
                    monitoring results may be different.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <div style={{ margin: '0 auto' }}>
          {!canSeeDetails && (
            <div
              className="ui button column  submit"
              onClick={onSeeDetailsClick}
            >
              See Details
            </div>
          )}
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
