import { Auth } from 'aws-amplify';
import AddTokenToOptions from '../../AddTokenToOptions';
import { ITriggeredNotification } from '../../domain/interfaces/Notifications/ITriggeredNotification';
import { IAlert } from '../../stores/AlertStore';
import { IAlertService } from './IAlertService';

const error = new Error('Something went wrong');

export class AlertService implements IAlertService {
  private readonly baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = `${baseUrl}`;
  }

  public saveAlert = async (alertToSave: IAlert): Promise<void> => {
    const currentUser = await Auth.currentAuthenticatedUser();
    await fetch(
      `${this.baseUrl}/alerts`,
      await AddTokenToOptions({
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          userId: currentUser.username,
          market: alertToSave.market,
          alertId: alertToSave.id,
          name: alertToSave.name,
          symbol: alertToSave.symbol,
          downswingPercent: alertToSave.downswingPercent,
          upswingPercent: alertToSave.upswingPercent,
          emailAlertEnabled: alertToSave.emailAlerts,
          textAlertEnabled: alertToSave.textAlerts,
          dateCreated: alertToSave.dateCreated,
        }),
      }),
    );
  };

  public getAlerts = async (): Promise<IAlert[]> => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const response = await fetch(
      `${this.baseUrl}/alerts/${currentUser.username}`,
      await AddTokenToOptions({
        mode: 'cors',
        method: 'get',
      }),
    );

    if (response.status == 200) {
      const parsedResponse = await response.json();
      return Object.keys(parsedResponse.alerts).map((alertId: any): IAlert => {
        const alertDetails: {
          name: string;
          symbol: string;
          market: string;
          downswingPercent: number;
          upswingPercent: number;
          emailAlertEnabled: boolean;
          textAlertEnabled: boolean;
          dateCreated: string;
        } = parsedResponse.alerts[alertId];
        return {
          downswingPercent: alertDetails.downswingPercent,
          emailAlerts: alertDetails.emailAlertEnabled,
          id: alertId,
          name: alertDetails.name,
          market: alertDetails.market ?? 'stock',
          symbol: alertDetails.symbol,
          textAlerts: alertDetails.textAlertEnabled,
          upswingPercent: alertDetails.upswingPercent,
          dateCreated: alertDetails.dateCreated,
        };
      });
    } else {
      throw error;
    }
  };

  public getTriggeredNotificationForSymbol = async (
    stockSymbol: string,
  ): Promise<ITriggeredNotification[]> => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const response = await fetch(
      `${this.baseUrl}/notifications?userId=${currentUser.username}&stockSymbol=${stockSymbol}`,
      await AddTokenToOptions({
        mode: 'cors',
        method: 'get',
      }),
    );

    if (response.status == 200) {
      return await response.json();
    }
    return [];
  };

  public deleteAlert = async (alertToDelete: IAlert): Promise<void> => {
    const currentUser = await Auth.currentAuthenticatedUser();
    await fetch(
      `${this.baseUrl}/alerts/${currentUser.username}/${alertToDelete.id}`,
      await AddTokenToOptions({
        method: 'delete',
        mode: 'cors',
      }),
    );
  };
}
