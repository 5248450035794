import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { sendAccountSettingsUpdatedEmailAsync } from '../Email/AccountUpdatedEmailSender';
const CognitoAttributesUpdateSuccessResponse = 'SUCCESS';

export const changePassword: (
  oldPassword: string,
  newPassword: string,
) => Promise<void> = async (oldPassword, newPassword) => {
  const user = await Auth.currentAuthenticatedUser();

  await Auth.changePassword(user, oldPassword, newPassword);

  await sendAccountSettingsUpdatedEmailAsync(
    'The password on your account has been changed.',
    user,
  );
};

export const changeEmail: (newEmail: string) => Promise<void> = async (
  newEmail,
) => {
  const user = await Auth.currentAuthenticatedUser();

  const emailUpdateResponse = await Auth.updateUserAttributes(user, {
    email: newEmail,
  });

  if (emailUpdateResponse === CognitoAttributesUpdateSuccessResponse) {
    // send account update email to both new email address and old email address
    await sendAccountSettingsUpdatedEmailAsync(
      'The email address on your account has been changed.',
    );
    await sendAccountSettingsUpdatedEmailAsync(
      'The email address on your account has been changed.',
      user,
    );
  }
};

export const verifyEmail: (code: string) => Promise<void> = async (code) => {
  await Auth.verifyCurrentUserAttributeSubmit('email', code);
};

export const changePhoneNumber: (newPhoneNumber: string) => Promise<void> =
  async (newPhoneNumber) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, { phone_number: newPhoneNumber });
    await Auth.verifyUserAttribute(user, 'phone_number');

    await sendAccountSettingsUpdatedEmailAsync(
      'The phone number on your account has been changed.',
      user,
    );
  };

export const verifyPhoneNumber: (code: string) => Promise<void> = async (
  code,
) => {
  await Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
};

export const startVerifyUserPhoneNumber: (user?: CognitoUser) => Promise<void> =
  async (user = undefined) => {
    const currentUser = user || (await Auth.currentAuthenticatedUser());
    await Auth.verifyUserAttribute(currentUser, 'phone_number');
  };

export const isUserPhoneNumberVerified: (
  user?: CognitoUser,
) => Promise<boolean> = async (user = undefined) => {
  const workingUser = user || (await Auth.currentAuthenticatedUser());
  const phoneNumberVerified: any = await new Promise((resolve, reject) => {
    workingUser.getUserAttributes((error: any, attributes: any) => {
      const phoneNumberVerifiedAttribute = attributes?.find(
        (a: any) => a.Name === 'phone_number_verified',
      );
      if (error) {
        reject(error);
      }
      resolve(phoneNumberVerifiedAttribute);
    });
  });
  return phoneNumberVerified?.Value === 'true';
};

export const isValidEmail: (newEmail: string) => boolean = (newEmail) => {
  const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return !!newEmail.match(re);
};
