import React from 'react';
import { Segment } from 'semantic-ui-react';
import { Colors } from '../Colors';
import { SymbolSelectorProps } from '../Domain/Interfaces/SymbolSelectorProps';
import { SymbolSearch } from '../SymbolSearch/SymbolSearch';

export const SymbolSelector: React.FC<SymbolSelectorProps> = ({
  submitHandler,
  selectedSymbol,
}) => {
  if (!selectedSymbol) {
    return (
      <>
        <div
          className="ui segment"
          style={{
            maxWidth: 590,
            flex: 1,
            justifyContent: 'center',
            backgroundColor: Colors.darkGreen,
          }}
        >
          <div className="ui inverted form">
            <div className="field">
              <label style={{ textAlign: 'left' }}>
                Enter Your Investment Symbol:
              </label>
              <SymbolSearch
                onSelected={async (e) => {
                  await submitHandler(e.symbol);
                }}
              ></SymbolSearch>
            </div>
          </div>
        </div>
        <Segment
          style={{
            maxWidth: 590,
            backgroundColor: '#002f37',
            color: 'white',
            border: `solid 1px #E0E0E0`,
            padding: 15,
            fontSize: '1.1em',
          }}
        >
          CompareAlert allows you to look back at how an investment compares
          using myRiskAlert versus holding the investment. Once you enter your
          investment symbol you can enter your total original cost, current
          number of shares, and your risk and opportunity percentages. Choose
          your start date and get instant results through today's date.
          <br />
          <br />A great tool to look at history and can help you determine
          percentages for your particular investment going forward.
        </Segment>
      </>
    );
  } else {
    return <></>;
  }
};
