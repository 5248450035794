import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import 'semantic-ui-less/semantic.less';
import { Divider, Grid, Header, Loader } from 'semantic-ui-react';
import { v4 } from 'uuid';
import { ISearchedItem } from '../../services/Stock/IStockService';
import { useAlertServiceContext } from '../RiskAlertContext/RiskAlertContext';
import { AlertForm } from './AlertForm';
import { SymbolSearch } from './SymbolSearch';

export const CompactQuestionnaire: React.FC = () => {
  const { alertStore, stockService } = useAlertServiceContext();
  const currentNumberOfAlerts = alertStore.userAlerts.length;

  const [selectedNewItem, setSelectedNewItem] = useState<
    ISearchedItem | undefined
  >();

  const [isLoading, setIsLoading] = useState(false);
  const cancelEditing = () => {
    setSelectedNewItem(undefined);
  };

  return (
    <>
      {isLoading && <Loader active={isLoading} />}
      {!isLoading && (
        <Grid
          stackable
          style={{
            justifyText: 'center',
            maxWidth: '800px',
            padding: 14,
          }}
        >
          <Grid.Column style={{ paddingLeft: '0' }} verticalAlign="middle">
            <Grid.Row>
              <Header size="small" textAlign="left">
                Add New Investment Symbol or Enter Name
              </Header>
              <SymbolSearch
                onSearch={async (x) => {
                  return await stockService.search(x);
                }}
                onSelected={(selectedItem: ISearchedItem) => {
                  setSelectedNewItem(selectedItem);
                }}
              />
            </Grid.Row>
            <Divider hidden />
            {selectedNewItem && (
              <Grid.Row>
                <AlertForm
                  alertLimitReached={currentNumberOfAlerts >= 50}
                  isLoading={alertStore.isLoading}
                  data-testid="editNewAlertFrom"
                  cancelButtonLabel="Cancel"
                  onSaveClick={async (savedAlert) => {
                    setIsLoading(true);
                    await alertStore.addAlert(savedAlert);
                    cancelEditing();
                    setIsLoading(false);
                  }}
                  inputAlert={{
                    market: selectedNewItem.market,
                    id: v4(),
                    emailAlerts: true,
                    name: selectedNewItem.name,
                    symbol: selectedNewItem.symbol,
                    downswingPercent: 0,
                    textAlerts: true,
                    upswingPercent: 0,
                    dateCreated: new Date().toUTCString(),
                  }}
                  onCancelClick={async () => {
                    cancelEditing();
                  }}
                />
              </Grid.Row>
            )}
          </Grid.Column>
        </Grid>
      )}
    </>
  );
};

export default observer(CompactQuestionnaire);
