import React from 'react';
import { ChartData, Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { FormattedWeeklyData } from '../Domain/Interfaces/FormattedWeeklyData';
import { Transaction } from '../Domain/Interfaces/Transaction';
import { Colors } from '../Colors';
import { Segment } from 'semantic-ui-react';

export interface ILineChart {
  formattedData: FormattedWeeklyData[];
  sellTransactions: Transaction[];
  buyTransactions: Transaction[];
}

// eslint-disable-next-line max-lines-per-function
export const LineChart: React.FC<ILineChart> = ({
  formattedData,
  sellTransactions,
  buyTransactions,
}) => {
  const regularData = formattedData.map((data: FormattedWeeklyData) => {
    return +data.splitAdjustedClosePrice;
  });

  const buyAlertChartData: (number | null)[] = [];
  const sellAlertChartData: (number | null)[] = [];

  const labels = formattedData.map((data: FormattedWeeklyData) => {
    return data.date;
  });

  formattedData.forEach((weeklyDate: FormattedWeeklyData) => {
    const buyEvent = buyTransactions.find(
      (b: Transaction) => b.date === weeklyDate.date,
    );
    const sellEvent = sellTransactions.find(
      (s: Transaction) => s.date === weeklyDate.date,
    );

    if (buyEvent) {
      buyAlertChartData.push(buyEvent.splitAdjustedSharePrice);
      sellAlertChartData.push(null);
    } else if (sellEvent) {
      sellAlertChartData.push(sellEvent.splitAdjustedSharePrice);
      buyAlertChartData.push(null);
    } else {
      buyAlertChartData.push(null);
      sellAlertChartData.push(null);
    }
  });

  sellAlertChartData[0] = null;

  const data: ChartData<Chart.ChartData> = {
    labels: labels,
    datasets: [
      {
        label: 'OpportunityAlert',
        data: sellAlertChartData,
        fill: false,
        pointStyle: 'circle',
        borderColor: Colors.riseGreen,
        pointRadius: 6,
        pointHoverRadius: 10,
        pointBackgroundColor: Colors.riseGreen,
        pointBorderColor: Colors.riseGreen,
        pointHoverBackgroundColor: Colors.riseGreen,
        pointHoverBorderColor: Colors.riseGreen,
        showLine: false,
      },
      {
        label: 'RiskAlert',
        data: buyAlertChartData,
        fill: false,
        pointStyle: 'circle',
        borderColor: Colors.fallRed,
        pointRadius: 6,
        pointHoverRadius: 10,
        pointBackgroundColor: Colors.fallRed,
        pointBorderColor: Colors.fallRed,
        pointHoverBackgroundColor: Colors.fallRed,
        pointHoverBorderColor: Colors.fallRed,
        showLine: false,
      },
      {
        label: 'Price',
        data: regularData,
        fill: false,
        borderColor: Colors.offWhite,
        pointRadius: 0,
      },
    ],
  };

  const options: ChartOptions = {
    legend: {
      position: 'top',
      labels: {
        fontColor: Colors.offWhite,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: Colors.offWhite,
          },
          gridLines: {
            color: '#F0582533',
            display: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: Colors.offWhite,
          },
          gridLines: {
            color: '#F0582533',
            borderDash: [5, 2],
          },
        },
      ],
    },
  };

  const dot = {
    borderRadius: 20,
    padding: 10,
    width: 10,
    marginTop: 10,
    marginRight: 10,
  };

  const red = {
    backgroundColor: Colors.fallRed,
  };

  const green = {
    backgroundColor: Colors.riseGreen,
  };

  const text = {
    fontSize: 15,
    color: 'white',
  };

  return (
    <Segment
      secondary
      style={{
        width: '100%',
        borderRadius: 10,
        backgroundColor: Colors.darkGreen,
        padding: 10,
      }}
    >
      <Line data={data} options={options} />

      <div style={{ ...dot, ...green, float: 'left' }}></div>
      <div style={{ ...text, textAlign: 'left', paddingTop: 10 }}>
        Maximize Opportunity When Investment Reaches its Bottom
      </div>
      <div style={{ ...dot, ...red, float: 'left', clear: 'both' }}></div>
      <div style={{ ...text, textAlign: 'left', paddingTop: 10 }}>
        Minimize Risk of Rapidly Falling Markets
      </div>
    </Segment>
  );
};
