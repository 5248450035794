import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Icon,
  Item,
  Loader,
  Popup,
  Segment,
} from 'semantic-ui-react';
import { IAlert } from '../../stores/AlertStore';
import { useAlertServiceContext } from '../RiskAlertContext/RiskAlertContext';

interface ISavedAlertProps {
  alert: IAlert;
  onEditClick: (editingAlert: IAlert) => void;
  onChartClick: (symbol: string, dateCreated: string) => Promise<void>;
  hideChartButton: boolean;
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const SavedAlert: React.FC<ISavedAlertProps> = (
  props: ISavedAlertProps,
) => {
  const { alertStore } = useAlertServiceContext();
  const { alert, onEditClick } = props;
  const [isDeleting, setIsDeleting] = useState(false);

  const onDeleteClick = async (alert: IAlert) => {
    setIsDeleting(true);
    await alertStore.removeAlert(alert).finally(() => {
      setIsDeleting(false);
    });
  };

  const onChartClick = async (symbol: string, dateCreated: string) => {
    await props.onChartClick(symbol, dateCreated);
    scrollToTop();
  };

  const bgColor = props.alert.market !== 'CC' ? 'rgb(0, 47, 55)' : '#235c63';

  return (
    <Segment secondary style={{ padding: 30, backgroundColor: bgColor }}>
      <Dimmer active={isDeleting}>
        <Loader />
      </Dimmer>
      <Grid>
        <Grid.Column style={{ padding: 0 }} width={6} verticalAlign="middle">
          <Item>
            <Item.Content>
              <Item.Header>
                <Header size="tiny" inverted>
                  {alert.name}
                </Header>
              </Item.Header>
              <Item.Meta style={{ color: 'lightgray' }}>
                {alert.symbol}
              </Item.Meta>
              <Item.Meta style={{ color: 'lightgray' }}>
                {`Start Date: ${new Date(
                  alert.dateCreated ?? '',
                ).toLocaleDateString()}`}
              </Item.Meta>
            </Item.Content>
          </Item>
        </Grid.Column>
        <Grid.Column width={2} verticalAlign="middle">
          {alert.emailAlerts && (
            <Popup
              content="Email Alerts"
              trigger={
                <Icon name="envelope" circular style={{ color: 'white' }} />
              }
            />
          )}
        </Grid.Column>
        <Grid.Column width={2} verticalAlign="middle">
          {alert.textAlerts && (
            <Popup
              content="Text Message Alerts"
              trigger={
                <Icon name="mobile" circular style={{ color: 'white' }} />
              }
            />
          )}
        </Grid.Column>
        <Grid.Column width={2} verticalAlign="middle">
          <Popup
            content="Edit Risk Alert"
            trigger={
              <Button
                size={'mini'}
                data-testid="editSavedButton"
                disabled={isDeleting}
                data-content="Edit Risk Alert"
                onClick={async () => {
                  onEditClick(alert);
                  scrollToTop();
                }}
                icon="pencil alternate"
              />
            }
          />
        </Grid.Column>
        <Grid.Column
          style={{ justifyText: 'center' }}
          width={2}
          verticalAlign="middle"
        >
          <Popup
            content="Show One Year Chart"
            trigger={
              <Button
                size={'mini'}
                data-testid="showChartButton"
                color="green"
                data-content="Show a one year chart"
                icon="chart line"
                onClick={async () => {
                  await onChartClick(alert.symbol, alert.dateCreated || '');
                  scrollToTop();
                }}
              />
            }
          />
        </Grid.Column>
        <Grid.Column width={2} verticalAlign="middle">
          <Popup
            content="Delete Risk Alert"
            trigger={
              <Button
                size={'mini'}
                data-testid="deleteSavedButton"
                color="red"
                icon="trash"
                onClick={async () => {
                  await onDeleteClick(alert);
                }}
              />
            }
          />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default observer(SavedAlert);
