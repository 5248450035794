import React, { useEffect } from 'react';
import Engine from '../Engine';
import { FormatData } from '../Functions/FormatData';
import { GetHistoricalData } from '../Functions/GetHistoricalData';
import { Form } from '../SimulatorForm/Form';
import { SymbolSelector } from '../SimulatorSymbolSelector/SymbolSelector';
import { DetailsBlock } from '../SimulatorDashboardComponents/DetailsBlock';
import { Results } from '../SimulatorDashboardComponents/Results';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useAlertServiceContext } from '../../RiskAlertContext/RiskAlertContext';
import { observer } from 'mobx-react-lite';

export const CompareAlertDashboard: React.FC = observer(() => {
  const { compareAlertViewModel } = useAlertServiceContext();

  const now = new Date();
  const yearPrior = new Date();
  yearPrior.setFullYear(now.getFullYear() - 1);
  useEffect(() => {
    if (compareAlertViewModel.historicalData.length > 0) {
      compareAlertViewModel.setCurrentSharePrice(
        +compareAlertViewModel.historicalData[0].closePrice,
      );
    }
  }, [compareAlertViewModel.historicalData]);

  const formSubmitHandler = async (quantity: number): Promise<void> => {
    try {
      compareAlertViewModel.setIsLoading(true);
      const formatedData = await loadData();
      compareAlertViewModel.setShowForm(false);
      const engine = new Engine(
        quantity,
        compareAlertViewModel.riseAlert,
        compareAlertViewModel.fallAlert,
        formatedData,
        compareAlertViewModel.currentSharePrice,
        compareAlertViewModel.beginningValue,
      );
      const results = engine.runSimulation();
      compareAlertViewModel.setHistoricalData(formatedData);
      compareAlertViewModel.setCurrentSharePrice(+formatedData[0].closePrice);
      compareAlertViewModel.setEngineResults(results);
    } finally {
      compareAlertViewModel.setIsLoading(false);
    }
  };

  const symbolSelectionHandler = (symbol: string): void => {
    compareAlertViewModel.setSelectedSymbol(symbol.toUpperCase());
  };

  const loadData = async () => {
    const data = await GetHistoricalData(
      compareAlertViewModel.selectedSymbol.toUpperCase(),
      compareAlertViewModel.startDate.toISOString().split('T')[0],
      new Date().toISOString().split('T')[0],
    );

    return FormatData(data, compareAlertViewModel.startDate);
  };

  const detailsClickHandler = (value: boolean): void => {
    compareAlertViewModel.setSeeDetails(value);
  };

  const resetClickHandler = () => {
    compareAlertViewModel.setSeeDetails(false);
    compareAlertViewModel.setHistoricalData([]);
    compareAlertViewModel.setEngineResults(undefined);
    compareAlertViewModel.setSelectedSymbol('');
    compareAlertViewModel.setShowForm(true);
    compareAlertViewModel.setRiseAlert(0.1);
    compareAlertViewModel.setFallAlert(0.1);
    compareAlertViewModel.setStartDate(yearPrior);
    compareAlertViewModel.setBeginningValue(0);
  };

  const editClickHandler = () => {
    compareAlertViewModel.setHistoricalData([]);
    compareAlertViewModel.setEngineResults(undefined);
    compareAlertViewModel.setShowForm(true);
    compareAlertViewModel.setSeeDetails(false);
  };

  return (
    <Dimmer.Dimmable
      dimmed={compareAlertViewModel.isLoading}
      style={{
        margin: '1rem',
      }}
    >
      <Dimmer inverted active={compareAlertViewModel.isLoading}>
        <Loader />
      </Dimmer>
      <SymbolSelector
        submitHandler={symbolSelectionHandler}
        selectedSymbol={compareAlertViewModel.selectedSymbol}
      />

      <Form
        submitHandler={formSubmitHandler}
        currentSharePrice={compareAlertViewModel.currentSharePrice}
        selectedSymbol={compareAlertViewModel.selectedSymbol}
        shouldSeeForm={compareAlertViewModel.showForm}
        engineResults={compareAlertViewModel.engineResults}
        setRiseAlert={compareAlertViewModel.setRiseAlert}
        setFallAlert={compareAlertViewModel.setFallAlert}
        fallAlert={compareAlertViewModel.fallAlert}
        resetClickHandler={resetClickHandler}
        riseAlert={compareAlertViewModel.riseAlert}
        setStartDate={compareAlertViewModel.setStartDate}
        startDate={compareAlertViewModel.startDate}
        beginningValue={compareAlertViewModel.beginningValue}
        setBeginningValue={compareAlertViewModel.setBeginningValue}
      />

      <Results
        engineResults={compareAlertViewModel.engineResults}
        canSeeDetails={compareAlertViewModel.seeDetails}
        detailsClickHandler={detailsClickHandler}
        selectedSymbol={compareAlertViewModel.selectedSymbol}
        resetClickHandler={resetClickHandler}
        editClickHandler={editClickHandler}
      />

      <DetailsBlock
        engineResults={compareAlertViewModel.engineResults}
        canSeeDetails={compareAlertViewModel.seeDetails}
      />
    </Dimmer.Dimmable>
  );
});
