import { Auth } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import authStorage from './services/AuthenticationStorage';
import { install } from 'ga-gtag';

const config = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    storage: authStorage,
  },
};

Auth.configure(config);
install('AW-10845770200/84pgCIP-q5QDENij1bMo');

ReactDOM.render(<App />, document.getElementById('root'));
