import { Auth } from 'aws-amplify';
import AddTokenToOptions from '../../AddTokenToOptions';
import { IUser } from '../../stores/UserStore';
import { IUserService } from './IUserService';

const error = new Error('Something went wrong');

export class UserService implements IUserService {
  private readonly baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = `${baseUrl}`;
  }

  public getUser = async (): Promise<IUser> => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const response = await fetch(
      `${this.baseUrl}/users/${currentUser.username}`,
      await AddTokenToOptions({
        mode: 'cors',
        method: 'get',
      }),
    );

    if (response.status == 200) {
      const parsedResponse = await response.json();
      return {
        customerId: parsedResponse.customerId ?? undefined,
        paymentMethodId: parsedResponse.paymentMethodId ?? undefined,
        subscriptionId: parsedResponse.subscriptionId ?? undefined,
        subscriptionItemId: parsedResponse.subscriptionItemId ?? undefined,
        paymentMethodLastFour:
          parsedResponse.paymentMethodLastFour ?? undefined,
        id: parsedResponse.userId,
      };
    } else if (response.status !== 404) {
      throw error;
    } else {
      return {
        customerId: undefined,
        paymentMethodId: undefined,
        subscriptionId: undefined,
        subscriptionItemId: undefined,
        paymentMethodLastFour: undefined,
        id: '',
      };
    }
  };

  public createCustomer = async (): Promise<IUser> => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const response = await fetch(
      `${this.baseUrl}/stripe-customer`,
      await AddTokenToOptions({
        mode: 'cors',
        method: 'post',
        body: JSON.stringify({
          userId: currentUser.username,
          email: currentUser.attributes.email,
        }),
      }),
    );

    if (response.status == 200) {
      const parsedResponse = await response.json();
      return {
        customerId: parsedResponse.customerId,
        paymentMethodId: undefined,
        subscriptionId: undefined,
        subscriptionItemId: undefined,
        paymentMethodLastFour: undefined,
        id: currentUser.username,
      };
    } else {
      throw error;
    }
  };

  public updatePaymentMethod = async (
    paymentMethodId: string,
    customerId: string,
  ): Promise<IUser> => {
    const currentUser = await Auth.currentAuthenticatedUser();

    const response = await fetch(
      `${this.baseUrl}/payment-methods`,
      await AddTokenToOptions({
        mode: 'cors',
        method: 'put',
        body: JSON.stringify({
          userId: currentUser.username,
          customerId: customerId,
          paymentMethodId: paymentMethodId,
        }),
      }),
    );

    if (response.status == 200) {
      const parsedResponse = await response.json();
      return {
        customerId: parsedResponse.customerId,
        paymentMethodId: parsedResponse.paymentMethodId,
        subscriptionId: parsedResponse.subscriptionId,
        subscriptionItemId: parsedResponse.subscriptionItemId,
        paymentMethodLastFour:
          parsedResponse.paymentMethodLastFour ?? undefined,
        id: parsedResponse.userId,
      };
    } else {
      throw error;
    }
  };

  public createSubscription = async (
    paymentMethodId: string,
    customerId: string,
    promotionCode: string,
  ): Promise<IUser> => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const response = await fetch(
      `${this.baseUrl}/subscription`,
      await AddTokenToOptions({
        mode: 'cors',
        method: 'put',
        body: JSON.stringify({
          userId: currentUser.username,
          customerId: customerId,
          PaymentMethodId: paymentMethodId,
          promotionCode,
        }),
      }),
    );

    if (response.status == 200) {
      const parsedResponse = await response.json();
      return {
        customerId: parsedResponse.customerId,
        paymentMethodId: parsedResponse.paymentMethodId,
        subscriptionId: parsedResponse.subscriptionId,
        subscriptionItemId: parsedResponse.subscriptionItemId,
        paymentMethodLastFour:
          parsedResponse.paymentMethodLastFour ?? undefined,
        id: parsedResponse.userId,
      };
    } else {
      throw error;
    }
  };

  public cancelSubscription = async (): Promise<IUser> => {
    const currentUser = await Auth.currentAuthenticatedUser();
    await fetch(
      `${this.baseUrl}/subscription/${currentUser.username}`,
      await AddTokenToOptions({
        mode: 'cors',
        method: 'delete',
        body: JSON.stringify({
          userId: currentUser.username,
        }),
      }),
    );
    return {
      customerId: undefined,
      paymentMethodId: undefined,
      subscriptionId: undefined,
      subscriptionItemId: undefined,
      paymentMethodLastFour: undefined,
      id: currentUser.username,
    };
  };

  public checkSubscription = async (): Promise<boolean> => {
    return Promise.resolve(true);
    // const currentUser = await Auth.currentAuthenticatedUser();

    // const result = await fetch(
    //   `${this.baseUrl}/subscription/status/${currentUser.username}`,
    //   await AddTokenToOptions({
    //     mode: 'cors',
    //     method: 'get',
    //   }),
    // );

    // if (result.status === 200) {
    //   const parsedResponse = await result.json();

    //   return parsedResponse.isActive;
    // } else {
    //   return false;
    // }
  };
}
