import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import { IUserService } from '../services/User/IUserService';

export const User = types.model('User', {
  id: types.string,
  customerId: types.maybe(types.string),
  paymentMethodId: types.maybe(types.string),
  subscriptionId: types.maybe(types.string),
  subscriptionItemId: types.maybe(types.string),
  paymentMethodLastFour: types.maybe(types.string),
});

export const UserStore = types
  .model('UserStore', {
    user: types.maybe(User),
    isLoading: types.boolean,
  })
  .actions((self) => {
    const userService: IUserService = getEnv(self).userService;

    const wrapLoadingDuringAction = flow(function* (serviceAction: any) {
      self.isLoading = true;
      try {
        const result = yield serviceAction();
        self.isLoading = false;
        return result;
      } catch (err) {
        console.error(err);
        self.isLoading = false;
        throw err;
      }
    });

    return {
      loadUser: flow(function* () {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const user = yield userService.getUser();
            self.user = user;
          }),
        );
      }),
      updateUser: flow(function* (user: IUser) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            Object.assign(self.user, user);
          }),
        );
      }),
      createCustomer: flow(function* () {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const user = yield userService.createCustomer();
            self.user = user;
          }),
        );
      }),
      createSubscription: flow(function* (
        paymentMethodId: string,
        customerId: string,
        promotionCode: string,
      ) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const user = yield userService.createSubscription(
              paymentMethodId,
              customerId,
              promotionCode,
            );
            Object.assign(self.user, user);
          }),
        );
      }),
      cancelSubscription: flow(function* () {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const user = yield userService.cancelSubscription();
            Object.assign(self.user, user);
          }),
        );
      }),
      checkSubscriptionStatus: flow(function* () {
        return yield wrapLoadingDuringAction(
          flow(function* () {
            return yield userService.checkSubscription();
          }),
        );
      }),
      updatePaymentMethod: flow(function* (
        paymentMethodId: string,
        customerId: string,
      ) {
        return yield wrapLoadingDuringAction(
          flow(function* () {
            return yield userService.updatePaymentMethod(
              paymentMethodId,
              customerId,
            );
          }),
        );
      }),
    };
  });

export interface IUser extends Instance<typeof User> {}
export interface IUserStore extends Instance<typeof UserStore> {}
