import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { Colors } from '../Colors';
import { ResultsHeaderProps } from '../Domain/Interfaces/ResultsHeaderProps';

export const ResultsHeader: React.FC<ResultsHeaderProps> = ({
  selectedSymbol,
  canSeeDetails,
  engineResults,
  resetClickHandler,
  editClickHandler,
}) => {
  if (engineResults && !canSeeDetails && selectedSymbol) {
    return (
      <div className="ui centered row">
        <div
          style={{
            height: '200px',
          }}
        >
          <h2 className="ui row">
            Your Selected Symbol:{' '}
            <span style={{ color: Colors.orange }}>{selectedSymbol}</span>
            <div style={{ marginTop: 10 }}>
              <Popup
                content="Edit"
                trigger={
                  <Button
                    style={{ marginLeft: 10, position: 'relative', top: -5 }}
                    onClick={editClickHandler}
                    primary
                    icon
                  >
                    <Icon name="pencil" />
                  </Button>
                }
              />
              <Popup
                content="Start Over"
                trigger={
                  <Button
                    style={{ marginLeft: 10, position: 'relative', top: -5 }}
                    onClick={resetClickHandler}
                    primary
                    icon
                  >
                    <Icon name="undo" />
                  </Button>
                }
              />
            </div>
          </h2>
          <div
            className="ui row"
            style={{
              marginTop: '10px',

              fontSize: '20px',
            }}
          >
            Risk Tolerance:
            <div
              className="ui column"
              style={{ marginTop: '10px', color: '#F05825', lineHeight: 1.2 }}
            >
              Risk Alert - {(engineResults.fallAlert * 100).toFixed(0)}% <br />
              Opportunity Alert - {(engineResults.riseAlert * 100).toFixed(0)}%
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
