import 'chartjs-plugin-annotation';
import React from 'react';
import { ChartData, Line } from 'react-chartjs-2';
import { FormattedDailyData } from '../../domain/interfaces/Models/FormattedDailyData';
import { Segment } from 'semantic-ui-react';
import { Colors } from '../CompareAlert/Colors';
import { ITriggeredNotification } from '../../domain/interfaces/Notifications/ITriggeredNotification';
import Chart from 'chart.js';

const ticksInADay = 86400000;

export interface LineChartProps {
  dailyData: FormattedDailyData[];
  triggers: ITriggeredNotification[];
  symbol: string;
  dateCreated: string;
}

export const LineChart: React.FC<LineChartProps> = ({
  dailyData,
  symbol,
  triggers,
  dateCreated,
}) => {
  const labels: string[] = [];
  const closePrices: number[] = [];
  const riskDates: string[] = [
    ...Array.from(
      new Set(
        triggers
          .filter((t: ITriggeredNotification) => t.alertType === 'Risk')
          .map((t: ITriggeredNotification) => {
            return t.date;
          }),
      ),
    ),
  ];

  const opportunityDates: string[] = [
    ...Array.from(
      new Set(
        triggers
          .filter((t: ITriggeredNotification) => t.alertType === 'Opportunity')
          .map((t: ITriggeredNotification) => {
            return t.date;
          }),
      ),
    ),
  ];
  const buyAlertChartData: (number | null)[] = [];
  const sellAlertChartData: (number | null)[] = [];

  dailyData.forEach((d: FormattedDailyData) => {
    const buyEvent = opportunityDates.find(
      (b: string) => b === d.date.toString(),
    );
    const sellEvent = riskDates.find((b: string) => b === d.date.toString());

    if (buyEvent) {
      buyAlertChartData.push(+d.splitAdjustedClosePrice);
      sellAlertChartData.push(null);
    } else if (sellEvent) {
      sellAlertChartData.push(+d.splitAdjustedClosePrice);
      buyAlertChartData.push(null);
    } else {
      buyAlertChartData.push(null);
      sellAlertChartData.push(null);
    }

    closePrices.push(+d.splitAdjustedClosePrice);
    labels.push(d.date);
  });

  const chartData: ChartData<Chart.ChartData> = {
    labels: labels,
    datasets: [
      {
        label: 'RiskAlert - Opportunity',
        data: buyAlertChartData,
        fill: false,
        pointStyle: 'circle',
        borderColor: Colors.riseGreen,
        pointRadius: 6,
        pointHoverRadius: 10,
        pointBackgroundColor: Colors.riseGreen,
        pointBorderColor: Colors.riseGreen,
        pointHoverBackgroundColor: Colors.riseGreen,
        pointHoverBorderColor: Colors.riseGreen,
        showLine: false,
      },
      {
        label: 'RiskAlert - Risk',
        data: sellAlertChartData,
        fill: false,
        pointStyle: 'circle',
        borderColor: Colors.fallRed,
        pointRadius: 6,
        pointHoverRadius: 10,
        pointBackgroundColor: Colors.fallRed,
        pointBorderColor: Colors.fallRed,
        pointHoverBackgroundColor: Colors.fallRed,
        pointHoverBorderColor: Colors.fallRed,
        showLine: false,
      },
      {
        label: 'RiskAlert - Price Chart',
        data: closePrices,
        pointStyle: 'rectRounded',
        fill: false,
        borderColor: 'white',
        borderWidth: 1,
        pointRadius: 1,
        pointHoverRadius: 3,
        pointBackgroundColor: 'white',
        pointBorderColor: 'white',
        pointHoverBackgroundColor: 'white',
        pointHoverBorderColor: 'white',
      },
    ],
  };

  const yearMonthDay = (date: string) => {
    const d = new Date(date);
    let month = '' + (d.getUTCMonth() + 1);
    let day = '' + d.getUTCDate();
    const year = d.getUTCFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const findDateForCreateDate = () => {
    const date = new Date(dateCreated);
    let endDateIndex = labels.indexOf(yearMonthDay(date.toString()));
    let dayCount = 0;
    let searchDate = yearMonthDay(
      new Date(date.getTime() + ticksInADay * dayCount).toString(),
    );
    while (endDateIndex === -1 && dayCount < 10) {
      endDateIndex = labels.indexOf(yearMonthDay(searchDate));
      dayCount++;
      searchDate = yearMonthDay(
        new Date(date.getTime() + ticksInADay * dayCount).toString(),
      );
    }

    return searchDate;
  };

  const options: any = {
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: findDateForCreateDate(),
          borderColor: Colors.orange,
          label: {
            backgroundColor: 'rgba(0,0,0,.5)',
            content: 'Start of Monitoring',
            enabled: true,
            position: 'top',
            xAdjust: 60,
            rotation: 90,
          },
        },
      ],
    },
    legend: {
      position: 'top',
      labels: {
        fontColor: '#E0E0E0',
      },
    },
    title: {
      display: true,
      text: `Symbol: ${symbol} - One Year Chart`,
      fontColor: '#F05825E6',
      fontSize: 20,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: '#E0E0E0',
          },
          gridLines: {
            color: '#F0582533',
            display: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: '#E0E0E0',
          },
          gridLines: {
            color: '#F0582533',
            borderDash: [5, 2],
          },
        },
      ],
    },
  };

  return (
    <Segment
      secondary
      style={{
        border: `solid 1px #E0E0E0`,
        borderRadius: 5,
        backgroundColor: '#002f37',
      }}
    >
      <Line data={chartData} options={options} />
    </Segment>
  );
};
