import ISignUpConfig from '../../../domain/interfaces/Authentication/ISignUpConfig';

const SignUpConfig: ISignUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: 'Email',
      key: 'email',
      required: true,
      placeholder: 'Email',
      type: 'email',
      displayOrder: 1,
    },
    {
      label: 'First Name',
      key: 'given_name',
      required: true,
      placeholder: 'First Name',
      type: 'given_name',
      displayOrder: 2,
    },
    {
      label: 'Last Name',
      key: 'family_name',
      required: true,
      placeholder: 'Last Name',
      type: 'family_name',
      displayOrder: 3,
    },
    {
      label: 'Cell Number',
      key: 'phone_number',
      required: true,
      placeholder: 'Cell Number',
      type: 'phone_number',
      displayOrder: 4,
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      placeholder: 'Password',
      type: 'password',
      displayOrder: 5,
      hint: 'Minimum password length 8 characters. Password must include 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number.',
    },
  ],
};

export default SignUpConfig;
