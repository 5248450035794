import { Auth } from 'aws-amplify';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import { isIpadScreen } from '../../services/Layout';
import { useAlertServiceContext } from '../RiskAlertContext/RiskAlertContext';

const NavigationMenu: React.FunctionComponent = () => {
  const { alertStore } = useAlertServiceContext();
  const location = useLocation();
  const logout = async () => {
    await Auth.signOut();
    alertStore.clearAlerts();
  };

  return (
    <Menu
      vertical
      fluid
      secondary
      pointing
      style={{ marginTop: '3px', height: '100%' }}
      icon="labeled"
    >
      <Menu.Item
        as={Link}
        active={location.pathname === '/monitored-stocks'}
        to="/monitored-stocks"
      >
        <Icon name="server" />
        {isIpadScreen() && <>Stocks Being Monitored</>}
      </Menu.Item>
      <Menu.Item
        as={Link}
        active={location.pathname === '/compare-alert'}
        to="/compare-alert"
      >
        <Icon name="chart line" />
        {isIpadScreen() && <p>Compare&#8203;Alert</p>}
      </Menu.Item>
      <Menu.Item
        as={Link}
        active={location.pathname === '/user-profile'}
        to="/user-profile"
      >
        <Icon name="user outline" />
        {isIpadScreen() && <p>User Profile</p>}
      </Menu.Item>
      <Menu.Item onClick={logout} to="/">
        <Icon name="sign-out" />
        {isIpadScreen() && <p>Sign Out</p>}
      </Menu.Item>
    </Menu>
  );
};

export default NavigationMenu;
