import { SplitData } from '../../../domain/interfaces/Models/DailyInfoResponse';
import { DailyInfoResponse } from '../Domain/Interfaces/DailyInfoResponse';

export const GetHistoricalData = async (
  symbol: string,
  dateFrom: string,
  dateTo: string,
): Promise<DailyInfoResponse> => {
  const apiKey = process.env.REACT_APP_STOCK_API_KEY;
  const error = new Error('Something went wrong');

  const data: Response = await fetch(
    `https://eodhistoricaldata.com/api/eod/${symbol}?from=${dateFrom}&to=${dateTo}&api_token=${apiKey}&order=d&fmt=json`,
  );
  if (data.status !== 200) {
    throw error;
  }
  const splitResponse = await fetch(
    `https://eodhistoricaldata.com/api/splits/${symbol}?from=${dateFrom}&to=${dateTo}&api_token=${apiKey}&order=d&fmt=json`,
  );

  if (splitResponse.status !== 200) {
    throw error;
  }

  const splitData = await EODSplitResponseToSplit(splitResponse);
  return await EodResponseToDailyInfo(data, symbol, splitData);
};

const EODSplitResponseToSplit = async (
  response: Response,
): Promise<SplitData[]> => {
  const data = await response.json();

  return data.map((d: any) => {
    const split = d.split.split('/');
    return {
      date: d.date,
      ratio: split[0] / split[1],
    };
  });
};

const EodResponseToDailyInfo = async (
  response: Response,
  symbol: string,
  splitData: SplitData[],
) => {
  const dailyData = await response.json();
  let splitAdjustment = 1;
  const formattedData = [];

  for (let i = 0; i < dailyData.length; i++) {
    const data = dailyData[i];

    formattedData.unshift({
      close: data.close,
      symbol: symbol,
      date: new Date(data.date),
      exchange: data.exchage,
      name: data.name,
      splitAdjustment: splitAdjustment,
      splitAdjustedClose: data.adjusted_close,
    });

    const foundSplit = splitData.find((split) => split.date === data.date);

    if (foundSplit) {
      splitAdjustment *= foundSplit.ratio;
    }
  }

  return { data: formattedData };
};
