import React from 'react';
import { Card } from 'semantic-ui-react';
import { Colors } from '../Colors';
import { ResultCardProps } from '../Domain/Interfaces/ResultCardProps';
import { TriggerTransaction } from '../Domain/Interfaces/TriggerTransaction';
import { FormatPrice } from '../Functions/FormatPrice';

export const ResultCard: React.FC<ResultCardProps> = ({
  transaction,
  isFirst,
}) => {
  const cardBuyText = (transaction: TriggerTransaction) => {
    if (isFirst) {
      return `Starting with ${transaction.numberOfSharesBought.toFixed(
        2,
      )} shares with total cost of ${FormatPrice(
        transaction.buyCost.toFixed(2),
      )}`;
    }

    return `Bought ${transaction.numberOfSharesBought.toFixed(
      2,
    )} shares @ ${FormatPrice(
      transaction.splitAdjustedBuyPrice.toFixed(2),
    )} for a total of ${FormatPrice(transaction.buyCost.toFixed(2))}`;
  };

  const cardSellText = (transaction: TriggerTransaction) => {
    return `Sold ${transaction.numberOfSharesSold.toFixed(
      2,
    )} shares @ ${FormatPrice(transaction.splitAdjustedSellPrice.toFixed(2))}
    for a total of ${FormatPrice(transaction.sellTotal.toFixed(2))}`;
  };

  return (
    <Card
      fluid
      style={{
        backgroundColor: Colors.darkGreen,
        color: Colors.offWhite,
        borderRadius: 10,
      }}
    >
      <Card.Content style={{ padding: '15px 0' }}>
        {!isFirst && (
          <Card.Header
            style={{
              backgroundColor: Colors.riseGreen,
              color: Colors.offWhite,
              fontSize: 22,
            }}
          >
            OpportunityAlert
            <span
              style={{
                fontSize: 18,
                float: 'right',
                marginRight: 10,
              }}
            >
              {transaction.buyDate}
            </span>
          </Card.Header>
        )}
        <Card.Description
          style={{
            color: Colors.offWhite,
            fontSize: 18,
          }}
        >
          {cardBuyText(transaction)}
        </Card.Description>
      </Card.Content>

      <Card.Content style={{ padding: '15px 0' }}>
        {!transaction.isCleanup && (
          <>
            <Card.Header
              style={{
                backgroundColor: Colors.fallRed,
                color: Colors.offWhite,
                fontSize: 22,
              }}
            >
              RiskAlert
              <span
                style={{
                  fontSize: 18,
                  float: 'right',
                  marginRight: 10,
                }}
              >
                {transaction.sellDate}
              </span>
            </Card.Header>
            <Card.Description
              style={{
                padding: '10px',
                color: Colors.offWhite,
                fontSize: 18,
              }}
            >
              {cardSellText(transaction)}
            </Card.Description>
            <Card.Description
              style={{
                padding: '10px',
                color: Colors.offWhite,
                fontSize: 18,
              }}
            >
              Gain / Loss for this transaction is{' '}
              {FormatPrice(transaction.difference.toFixed(2))}
            </Card.Description>
          </>
        )}
      </Card.Content>
    </Card>
  );
};
