import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { Colors } from '../Colors';
import { FormHeaderProps } from '../Domain/Interfaces/FormHeaderProps';

export const FormHeader: React.FC<FormHeaderProps> = ({
  selectedSymbol,
  resetClickHandler,
}) => {
  if (selectedSymbol) {
    return (
      <div className="ui row">
        <div style={{}}>
          <h2 className="ui row">
            Your Selected Symbol:{' '}
            <span style={{ color: Colors.orange }}>{selectedSymbol}</span>
            <Popup
              content="Start Over"
              trigger={
                <Button
                  style={{ marginLeft: 10, position: 'relative', top: -5 }}
                  onClick={resetClickHandler}
                  primary
                  icon
                >
                  <Icon name="undo" />
                </Button>
              }
            />
          </h2>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
