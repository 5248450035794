import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { RiskAlertContext } from './components/RiskAlertContext/RiskAlertContext';
import { loadStripe } from '@stripe/stripe-js';
import { Routes } from './components/Routes/Routes';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

const App: React.FC = (): React.ReactElement => {
  return (
    <>
      <RiskAlertContext>
        <div style={{ height: '100%' }} data-testid="app">
          <BrowserRouter>
            <Elements stripe={stripePromise}>
              <Routes />
            </Elements>
          </BrowserRouter>
        </div>
      </RiskAlertContext>
    </>
  );
};

export default App;
