import React, { ReactElement } from 'react';

import { Grid, Header, Label, SearchResultProps } from 'semantic-ui-react';

export const SearchResultRow = (props: SearchResultProps): ReactElement => {
  const castedProps = props;

  const { name, symbol, market } = castedProps;

  return (
    <Grid columns="2" key={`search-${symbol}`} style={{ border: 0 }}>
      <Grid.Row style={{ border: 0 }}>
        <Grid.Column width={4} style={{ border: 0 }}>
          <Label style={{ backgroundColor: 'rgb(240, 88, 37)', border: 0 }}>
            {symbol}
          </Label>
        </Grid.Column>

        <Grid.Column width={12}>
          <Header size="tiny" inverted>
            {name}
            <Header.Subheader>Exchange: {market}</Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
