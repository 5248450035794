import { Auth } from 'aws-amplify';
import { PaymentMethod } from '@stripe/stripe-js';
import React, { useRef, useState } from 'react';

import { useAlertServiceContext } from '../RiskAlertContext/RiskAlertContext';
import {
  Grid,
  Form,
  Checkbox,
  Icon,
  Header,
  Message,
  Popup,
} from 'semantic-ui-react';
import CreditCardDetails from '../CreditCardDetails/CreditCardDetails';

export interface DefaultSignupFormProps {
  onSaveHandler: (
    paymentMethod: PaymentMethod,
    promotionCode: string,
  ) => Promise<void>;
}

export const DefaultSignupForm: React.FC<DefaultSignupFormProps> = ({
  onSaveHandler,
}) => {
  const [givenName, setGivenName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState('');
  const [acceptedPrice, setAcceptedPrice] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [promotionCode, setPromotionCode] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [errorMessage, setErrorMessage] = useState('');
  const creditCardDetails = useRef();
  const { alertStore } = useAlertServiceContext();

  const logout = () => {
    Auth.signOut();

    alertStore.clearAlerts();
  };

  const save = async () => {
    try {
      await onSaveHandler(paymentMethod!, promotionCode);
    } catch (ex: any) {
      setErrorMessage(ex.message);
    }
  };

  return (
    <>
      <Grid stackable style={{ flex: 1 }}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header>Please Enter Your Details</Header>
            <Form loading={false}>
              <div>
                <Grid stackable columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input
                        name="name"
                        label="Name"
                        placeholder="Name"
                        onChange={(e) => {
                          setGivenName(e.target.value);
                        }}
                        value={givenName}
                        required
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input
                        name="streetAddress"
                        label="Address"
                        placeholder="Address"
                        onChange={(e) => {
                          setStreetAddress(e.target.value);
                        }}
                        value={streetAddress}
                        required
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input
                        name="city"
                        label="City"
                        placeholder="City"
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        value={city}
                        required
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input
                        name="state"
                        label="State"
                        placeholder="State"
                        onChange={(e) => {
                          setState(e.target.value);
                        }}
                        value={state}
                        required
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="zipCode"
                        label="Zip Code"
                        placeholder="Zip Code"
                        onChange={(e) => {
                          setZipCode(e.target.value);
                        }}
                        value={zipCode}
                        required
                        type="number"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Form>
          </Grid.Column>
          <Grid.Column width={8}>
            <>
              <Header>Please Enter Your Credit Card Details</Header>

              <CreditCardDetails
                zipCode={zipCode}
                onPaymentMethodChange={(paymentMethod: PaymentMethod) => {
                  setIsVerified(true);
                  setPaymentMethod(paymentMethod);
                }}
                disableVerify={isVerified}
                ref={creditCardDetails}
              />
              <div>
                <br />
                <Popup
                  trigger={
                    <Checkbox
                      data-testid="userAgreement"
                      label="Starting at $2.99 a month for daily alerts"
                      onChange={() => {
                        setAcceptedPrice(!acceptedPrice);
                      }}
                      checked={acceptedPrice}
                    />
                  }
                  flowing
                  hoverable
                >
                  <Grid centered divided columns={3}>
                    <Grid.Column textAlign="center">
                      <Header as="h4">Basic</Header>
                      <p>
                        <b>$2.99</b>
                        <div>1-10 Stocks&ensp;</div>
                      </p>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <Header as="h4">Intermediate</Header>
                      <p>
                        <b>$4.99</b>
                        <div>11-20 Stocks</div>
                      </p>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <Header as="h4">Professional</Header>
                      <p>
                        <b>$6.99</b>
                        <div>21-50 Stocks &ensp;</div>
                      </p>
                    </Grid.Column>
                    <Grid.Row>
                      <Message color="teal" size="tiny">
                        *Monthly Cost is Determined by Number of Symbols
                        Monitored.
                      </Message>
                    </Grid.Row>
                  </Grid>
                </Popup>
                <br />
                <br />
                <Checkbox
                  data-testid="terms-agreement"
                  label="I have read and agree to the "
                  onChange={() => {
                    setAcceptedTerms(!acceptedTerms);
                  }}
                  checked={acceptedTerms}
                />{' '}
                <a
                  href="https://myriskalert.com/terms-of-use/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Terms & Conditions
                </a>
              </div>
              <p>&nbsp;</p>
              <Form>
                <Form.Input
                  label="Promo Code"
                  onChange={(e: any) => setPromotionCode(e.target.value)}
                  style={{ marginBottom: 15 }}
                ></Form.Input>
              </Form>

              {!errorMessage || <Message error>{errorMessage}</Message>}
              <Form.Button
                style={{ marginBottom: '.5rem' }}
                primary
                onClick={save}
                labelPosition="right"
                data-testid="save"
                floated="right"
                fluid
                icon
                disabled={!isVerified || !acceptedPrice || !acceptedTerms}
              >
                Next
                <Icon />
              </Form.Button>
              <Form.Button
                style={{ marginBottom: '.5rem' }}
                onClick={logout}
                labelPosition="right"
                data-testid="save"
                floated="right"
                fluid
                icon
              >
                Sign Out
              </Form.Button>
            </>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
