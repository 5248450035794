import React from 'react';
import { DetailsBlockProps } from '../Domain/Interfaces/DetailsBlockProps';
import { TriggerTransaction } from '../Domain/Interfaces/TriggerTransaction';
import { ResultCard } from './ResultCard';

export const DetailsBlock: React.FC<DetailsBlockProps> = ({
  engineResults,
  canSeeDetails,
}) => {
  if (engineResults && canSeeDetails) {
    return (
      <div
        className="ui grid"
        style={{
          marginTop: '20px',
          paddingBottom: '80px',
          textAlign: 'center',
          justifyContent: 'left',
        }}
        data-testid={'details-container'}
      >
        <div style={{ justifyContent: 'left' }}>
          {engineResults.alertTransactions.map(
            (transaction: TriggerTransaction, i: number) => {
              return (
                <div key={i}>
                  <ResultCard transaction={transaction} isFirst={i === 0} />
                </div>
              );
            },
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
