import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

const Dashboard: React.FC = () => {
  const history = useHistory();
  const buttonStyle = {
    height: 75,
    width: 400,
  };

  const containerStyle = {
    padding: 25,
  };

  return (
    <div style={containerStyle}>
      <Button
        primary
        style={buttonStyle}
        onClick={() => history.push('compare-alert')}
      >
        Use CompareAlert to Look at Past Stock Performance
      </Button>
      <br />
      <br />

      <Button
        primary
        style={buttonStyle}
        onClick={() => history.push('monitored-stocks')}
      >
        Stocks Being Monitored
      </Button>
    </div>
  );
};

export default Dashboard;
