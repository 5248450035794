import React, { useState } from 'react';
import { Button, Form, Grid, Header, Input } from 'semantic-ui-react';
import {
  changePhoneNumber,
  verifyPhoneNumber,
} from '../../services/User/UserProfileService';
import ErrorDisplay from '../Messaging/ErrorDisplay';
import SuccessDisplay from '../Messaging/SuccessDisplay';
import MaskedInput from 'react-text-mask';

const ChangePhoneNumber: React.FunctionComponent = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [changePhoneNumberSuccessMessage, setChangePhoneNumberSuccessMessage] =
    useState('');
  const [verificationSuccessMessage, setVerificationSuccessMessage] =
    useState('');

  const [showVerify, setShowVerify] = useState(false);
  const [code, setCode] = useState('');

  const onChangePhoneNumberClick = async () => {
    try {
      const formattedPhoneNumber = phoneNumber
        .replace('(', '')
        .replace(')', '')
        .replace('-', '')
        .replaceAll('_', '')
        .replace(' ', '');

      if (formattedPhoneNumber.length < 12) {
        setErrorMessage('Please enter a 10 digit phone number');
        return;
      }

      setErrorMessage('');
      setChangePhoneNumberSuccessMessage('');
      setVerificationSuccessMessage('');
      await changePhoneNumber(formattedPhoneNumber);
      setShowVerify(true);
      setChangePhoneNumberSuccessMessage(
        'Your phone number has been changed. Please check your text messages for a verification code.',
      );
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const onVerifyClick = async () => {
    try {
      setErrorMessage('');
      setVerificationSuccessMessage('');
      await verifyPhoneNumber(code);
      setVerificationSuccessMessage(
        'Thank you for verifying your phone number.',
      );
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Header>Change Phone Number</Header>
      <Form>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>New Phone Number</label>
                <Input fluid>
                  <MaskedInput
                    mask={[
                      '(',
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    placeholder="New Phone Number"
                    onChange={(e) => setPhoneNumber(`+1${e.target.value}`)}
                    name="phone"
                  />
                </Input>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                onClick={onChangePhoneNumberClick}
                primary
                style={{ width: 153 }}
              >
                Change Phone Number
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          {showVerify && (
            <>
              <Grid.Column>
                <SuccessDisplay message={changePhoneNumberSuccessMessage} />
              </Grid.Column>
              <Grid.Row>
                <Grid.Column>
                  <Input
                    placeholder="Code"
                    onChange={(e) => setCode(e.target.value)}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button
                    onClick={onVerifyClick}
                    primary
                    style={{ width: 153 }}
                  >
                    Verify
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          <Grid.Row>
            <Grid.Column>
              <ErrorDisplay message={errorMessage} />
              <SuccessDisplay message={verificationSuccessMessage} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
};

export default ChangePhoneNumber;
