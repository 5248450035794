import { Instance, types } from 'mobx-state-tree';

export const CompareAlertViewModel = types
  .model('CompareAlertViewModel', {
    showForm: types.boolean,
    selectedSymbol: types.string,
    historicalData: types.array(types.frozen()),
    currentSharePrice: types.number,
    engineResults: types.frozen(),
    seeDetails: types.boolean,
    riseAlert: types.number,
    fallAlert: types.number,
    startDate: types.Date,
    beginningValue: types.number,
    isLoading: types.boolean,
  })
  .actions((self) => ({
    setShowForm(value: boolean) {
      self.showForm = value;
    },
    setSelectedSymbol(value: string) {
      self.selectedSymbol = value;
    },
    setHistoricalData(value: any) {
      self.historicalData = value;
    },
    setCurrentSharePrice(value: number) {
      self.currentSharePrice = value;
    },
    setEngineResults(value: any) {
      self.engineResults = value;
    },
    setSeeDetails(value: boolean) {
      self.seeDetails = value;
    },
    setRiseAlert(value: number) {
      self.riseAlert = value;
    },
    setFallAlert(value: number) {
      self.fallAlert = value;
    },
    setStartDate(value: Date) {
      self.startDate = value;
    },
    setBeginningValue(value: number) {
      self.beginningValue = value;
    },
    setIsLoading(value: boolean) {
      self.isLoading = value;
    },
  }));

export interface ICompareAlertViewModel
  extends Instance<typeof CompareAlertViewModel> {}
