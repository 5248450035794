import React from 'react';
import { Grid } from 'semantic-ui-react';
import { CompareAlertDashboard } from '../components/CompareAlert/SimulatorDashboard/CompareAlertDashboard';

const CompareAlert: React.FC = () => {
  return (
    <>
      <Grid.Column textAlign={'left'} width={16}>
        <Grid.Row>
          <CompareAlertDashboard />
        </Grid.Row>
      </Grid.Column>
    </>
  );
};

export default CompareAlert;
