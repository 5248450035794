import { UserConfig } from '../Domain/Data/UserConfig';
import { ChangeResult } from '../Domain/Interfaces/ChangeResult';
import { FormattedWeeklyData } from '../Domain/Interfaces/FormattedWeeklyData';
import { Transaction } from '../Domain/Interfaces/Transaction';
import { TriggerTransaction } from '../Domain/Interfaces/TriggerTransaction';

export class AlertService {
  public CurrentMin: number;
  public CurrentMax: number;
  private _risePercentage: number;
  private _fallPercentage: number;
  private _sharesToBuy: number;
  private _currentShares: number;
  private _currentProceeds = 0;
  private _fallTransactions: Transaction[] = [];
  private _riseTransactions: Transaction[] = [];
  private _beginningValue = 0;

  private _transactionObject: TriggerTransaction = {
    buyCost: 0,
    buyPrice: 0,
    buyDate: '',
    sellDate: '',
    difference: 0,
    sellPrice: 0,
    sellTotal: 0,
    isCleanup: false,
    numberOfSharesBought: 0,
    numberOfSharesSold: 0,
    splitAdjustedBuyPrice: 0,
    splitAdjustedSellPrice: 0,
  };
  private _transactionHistory: TriggerTransaction[] = [];

  constructor(userConfig: UserConfig) {
    this.CurrentMin = userConfig.GetStartingPrice();
    this.CurrentMax = userConfig.GetStartingPrice();
    this._risePercentage = userConfig.GetRisePercentage();
    this._fallPercentage = userConfig.GetFallPercentage();
    this._sharesToBuy = userConfig.GetSharesToBuy();
    this._currentShares = userConfig.GetSharesToBuy();
    this._beginningValue = userConfig.GetBeginningValue();
  }

  public GetRiseTransactions = (): Transaction[] => {
    return this._riseTransactions;
  };

  public GetFallTransactions = (): Transaction[] => {
    return this._fallTransactions;
  };

  public ProcessChange = (
    changeResult: ChangeResult,
    currentSeries: FormattedWeeklyData,
  ): void => {
    if (changeResult.status === 'rise') {
      this.RiseEvent(currentSeries);
    } else {
      this.FallEvent(currentSeries);
    }
  };

  public RiseEvent = (currentSeries: FormattedWeeklyData): void => {
    const splitAdjustedClosePrice = +currentSeries.splitAdjustedClosePrice;
    const currentMin = this.CurrentMin;
    const percentageChangeFromMin = Math.abs(
      +((currentMin - splitAdjustedClosePrice) / splitAdjustedClosePrice) * 100,
    ).toFixed(2);
    const triggerAlert =
      +percentageChangeFromMin > this._risePercentage * 100 &&
      this._currentShares === 0;

    if (triggerAlert) {
      this.BuyShares(currentSeries);
    } else {
      this.CheckMinMax(+currentSeries.splitAdjustedClosePrice);
    }
  };

  public FallEvent = (currentSeries: FormattedWeeklyData): void => {
    const splitAdjustedClosePrice = +currentSeries.splitAdjustedClosePrice;

    const currentMax = this.CurrentMax;
    const percentageChangeFromMax = Math.abs(
      +((currentMax - splitAdjustedClosePrice) / this.CurrentMax) * 100,
    ).toFixed(2);

    const triggerAlert =
      +percentageChangeFromMax > this._fallPercentage * 100 &&
      this._currentShares !== 0;

    if (triggerAlert) {
      this.SellShares(currentSeries);
    } else {
      this.CheckMinMax(+currentSeries.splitAdjustedClosePrice);
    }
  };

  public SellShares = (currentSeries: FormattedWeeklyData): void => {
    if (this._transactionHistory.length == 0) {
      this._transactionObject.buyCost = this._beginningValue;
    }
    const splitAdjustedClosePrice = +currentSeries.splitAdjustedClosePrice;

    const returnedCapital = +(
      this._currentShares * splitAdjustedClosePrice
    ).toFixed(2);

    this._transactionObject.sellDate = currentSeries.date;
    this._transactionObject.sellPrice = splitAdjustedClosePrice;
    this._transactionObject.sellTotal = returnedCapital;

    this._transactionObject.difference =
      this._transactionObject.sellTotal - this._transactionObject.buyCost;
    this._transactionObject.numberOfSharesSold = this._currentShares;
    this._transactionObject.splitAdjustedSellPrice = splitAdjustedClosePrice;

    this._transactionHistory.push(this._transactionObject);

    this._fallTransactions.push({
      date: currentSeries.date,
      sharePrice: +currentSeries.closePrice,
      type: 'sell',
      splitAdjustedSharePrice: splitAdjustedClosePrice,
    });
    this.CurrentMax = splitAdjustedClosePrice;

    this._currentShares = 0;
    this._currentProceeds += returnedCapital;

    this.CurrentMin = splitAdjustedClosePrice;
  };

  public BuyShares = (
    currentSeries: FormattedWeeklyData,
    isFirst = false,
  ): void => {
    const splitAdjustedClosePrice = +currentSeries.splitAdjustedClosePrice;

    this._currentShares = !isFirst
      ? this._currentProceeds / splitAdjustedClosePrice
      : this._sharesToBuy;

    if (!isFirst) {
      this._currentProceeds -= this._currentShares * splitAdjustedClosePrice;
    } else {
      this._currentProceeds = 0;
    }

    const cost = this._currentShares * splitAdjustedClosePrice;
    debugger;
    const newTransaction: TriggerTransaction = {
      buyCost: cost,
      buyDate: currentSeries.date,
      buyPrice: +currentSeries.closePrice,
      splitAdjustedBuyPrice: splitAdjustedClosePrice,
      difference: 0,
      sellDate: '',
      sellPrice: 0,
      splitAdjustedSellPrice: 0,
      sellTotal: 0,
      isCleanup: false,
      numberOfSharesBought: this._currentShares,
      numberOfSharesSold: 0,
    };

    this._transactionObject = newTransaction;
    this._riseTransactions.push({
      date: currentSeries.date,
      sharePrice: +currentSeries.closePrice,
      type: 'buy',
      splitAdjustedSharePrice: splitAdjustedClosePrice,
    });

    this.CurrentMin = splitAdjustedClosePrice;
    this.CurrentMax = splitAdjustedClosePrice;
  };

  public CheckMinMax = (closePrice: number): void => {
    if (closePrice < this.CurrentMin) {
      this.CurrentMin = closePrice;
    } else if (closePrice > this.CurrentMax) {
      this.CurrentMax = closePrice;
    }
  };

  public GetTransactionHistory = (): TriggerTransaction[] => {
    return this._transactionHistory;
  };

  public CleanupShares = (closePrice: number): void => {
    if (this._currentShares) {
      this._transactionObject.sellPrice = closePrice;

      this._transactionObject.sellDate = new Date().toLocaleDateString();

      this._transactionObject.sellTotal = this._currentShares * closePrice;

      this._transactionObject.difference =
        this._transactionObject.sellTotal - this._transactionObject.buyCost;
      this._transactionObject.isCleanup = true;

      this._transactionHistory.push(this._transactionObject);
    }
  };
}
