import React from 'react';
import { ChartContainerProps } from '../Domain/Interfaces/ChartContainerProps';
import { LineChart } from '../SimulatorChart/LineChart';
import { Grid, Message } from 'semantic-ui-react';
import { isNotMinimumReadableChartWidth } from '../../../services/Layout';

export const ChartContainer: React.FC<ChartContainerProps> = ({
  engineResults,
}) => {
  if (engineResults) {
    return (
      <>
        <Grid.Row style={{ padding: '1rem', width: '100vw' }}>
          {isNotMinimumReadableChartWidth() && (
            <Message info>
              <p>Rotate screen horizontally to view chart.</p>
            </Message>
          )}
          <LineChart
            formattedData={engineResults.weeklyInfo}
            buyTransactions={engineResults.riseTriggerTransactions}
            sellTransactions={engineResults.fallTriggerTransaction}
          />
        </Grid.Row>
      </>
    );
  } else {
    return <></>;
  }
};
