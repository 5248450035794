import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import IAuthState from '../../domain/interfaces/Authentication/IAuthState';
import UserProfileDashboard from '../../pages/UserProfileDashboard';
import MonitoredStocks from '../../pages/MonitoredStocks';
import { Login } from '../Login/Login';
import Questionaire from '../Questionnaire/Questionnaire';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import AuthenticationStorage from '../../services/AuthenticationStorage';
import CompareAlert from '../../pages/CompareAlert';
import CollectInitialPaymentInfo from '../CollectInitialPaymentInfo/CollectInitialPaymentInfo';
import { withResponsiveContainer } from '../Layout/ResponsiveContainer';
import { Hub } from 'aws-amplify';
import Dashboard from '../../pages/Dashboard';

export const Routes: React.FC = () => {
  const [authState, setAuthState] = useState<IAuthState>({
    authState:
      AuthenticationStorage.getItem('amplify-authenticator-authState') || '',
  });

  useEffect(() => {
    Hub.listen('auth', (data) => {
      if (data.payload.event.toLowerCase() === 'signout') {
        setAuthState({ authState: data.payload.event.toLowerCase() });
      }
    });
  }, []);

  const authStateChange = (authStateChange: string): void => {
    setAuthState({
      authState: authStateChange.toLowerCase(),
    });
  };

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Redirect from="/" exact to="/dashboard" />
          <Route
            path="/login"
            render={(props) => (
              <>
                <Login
                  authState={authState}
                  onAuthStateChanged={authStateChange}
                  {...props}
                />
              </>
            )}
          />
          <AuthenticatedRoute
            authState={authState.authState}
            path="/user-profile"
            component={withResponsiveContainer(
              UserProfileDashboard,
              'User Profile',
            )}
          />
          <AuthenticatedRoute
            authState={authState.authState}
            path="/questionnaire"
            component={withResponsiveContainer(Questionaire, '')}
          />
          <AuthenticatedRoute
            authState={authState.authState}
            path="/dashboard"
            component={withResponsiveContainer(Dashboard, 'Dashboard')}
          />
          <AuthenticatedRoute
            authState={authState.authState}
            path="/monitored-stocks"
            component={withResponsiveContainer(
              MonitoredStocks,
              'Stocks Being Monitored',
            )}
          />
          <AuthenticatedRoute
            authState={authState.authState}
            path="/compare-alert"
            component={withResponsiveContainer(CompareAlert, 'CompareAlert')}
          />
          <AuthenticatedRoute
            authState={authState.authState}
            path="/collect-initial-payment-info"
            component={CollectInitialPaymentInfo}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
};
