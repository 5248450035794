import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, Message } from 'semantic-ui-react';
import {
  isUserPhoneNumberVerified,
  startVerifyUserPhoneNumber,
  verifyPhoneNumber,
} from '../../services/User/UserProfileService';
import { Form } from 'semantic-ui-react';
import ErrorDisplay from '../Messaging/ErrorDisplay';

const VerifyPhoneNumber: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [startingVerification, setStartingVerification] = useState(false);
  const [verificationStarted, setVerificationStarted] = useState(false);
  const [submittingCode, setSubmittingCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    isUserPhoneNumberVerified().then((isVerified) => {
      setIsVisible(!isVerified);
    });
  });

  const onVerifyClick = async () => {
    setStartingVerification(true);
    await wrapErrorHander(
      startVerifyUserPhoneNumber().then(() => setVerificationStarted(true)),
    );
    setStartingVerification(false);
  };

  const submitCodeClick = async () => {
    setSubmittingCode(true);
    await wrapErrorHander(
      verifyPhoneNumber(verificationCode).then(() =>
        setVerificationStarted(false),
      ),
    );
    setSubmittingCode(false);
  };

  const wrapErrorHander = async (asyncMethod: Promise<void>) => {
    setErrorMessage('');
    try {
      await asyncMethod;
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      {isVisible && (
        <Message style={{ textAlign: 'left' }} warning>
          Your phone number has not been verified. To receive text message
          alerts you must verify your phone number.
          <br />
          <br />
          <Button
            primary
            onClick={onVerifyClick}
            loading={startingVerification}
          >
            Verify Now
          </Button>
          {verificationStarted && (
            <>
              <br />
              <br />
              Please enter the code texted to your mobile phone.
              <Form.Input
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Code"
                fluid
              />
              <br />
              <Button
                primary
                onClick={submitCodeClick}
                style={{ width: 100 }}
                loading={submittingCode}
              >
                Submit
              </Button>
            </>
          )}
          <ErrorDisplay message={errorMessage} />
        </Message>
      )}
    </>
  );
};

export default observer(VerifyPhoneNumber);
