import React, { ReactElement } from 'react';
import { Grid, Header, Label, SearchResultProps } from 'semantic-ui-react';
import { ISearchedItem } from '../../services/Stock/IStockService';

export const SearchResultRow = (
  props: SearchResultProps | ISearchedItem,
): ReactElement => {
  const castedProps = props as any as ISearchedItem;

  const { name, symbol, market } = castedProps;
  return (
    <Grid columns="2" key={`search-${symbol}`}>
      <Grid.Row>
        <Grid.Column width={4}>
          <Label style={{ backgroundColor: 'rgb(240, 88, 37)', border: 0 }}>
            {symbol}
          </Label>
        </Grid.Column>
        <Grid.Column width={12}>
          <Header size="tiny" inverted>
            {name}
            <Header.Subheader>Exchange: {market}</Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
