import React, { useState } from 'react';
import { AlertForm } from '../components/Questionnaire/AlertForm';
import { useAlertServiceContext } from '../components/RiskAlertContext/RiskAlertContext';
import { Button, Grid, Loader, Message, Segment } from 'semantic-ui-react';
import { SavedAlertsList } from '../components/Questionnaire/SavedAlertsList';
import { IAlert } from '../stores/AlertStore';
import 'semantic-ui-less/semantic.less';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { LineChart } from '../components/LineChart/LineChart';
import { FormattedDailyData } from '../domain/interfaces/Models/FormattedDailyData';
import { CompactQuestionnaire } from '../components/Questionnaire/CompactQuestionnaire';
import { CreateSubscription } from '../components/CreateSubscription/CreateSubscription';
import { ITriggeredNotification } from '../domain/interfaces/Notifications/ITriggeredNotification';
import VerifyPhoneNumber from '../components/VerifyPhoneNumber/VerifyPhoneNumber';
import ErrorDisplay from '../components/Messaging/ErrorDisplay';
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';

import {
  isWideScreen,
  isNotMinimumReadableChartWidth,
} from '../services/Layout';

const MonitoredStocks: React.FC = () => {
  const { alertStore, stockService, userStore } = useAlertServiceContext();

  const [selectedEditingItem, setSelectedEditingItem] = useState<
    IAlert | undefined
  >();

  const [showYearlyChart, setShowYearlyChart] = useState<boolean>(false);
  const [pastYearData, setPastYearData] = useState<FormattedDailyData[]>([]);
  const [chartSymbol, setChartSymbol] = useState<string>('');
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const [notificationTriggers, setNotificationTriggers] = useState<
    ITriggeredNotification[]
  >([]);
  const [alertDateCreated, setAlertDateCreated] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const alertLimitReached = alertStore.userAlerts.length >= 50;

  const currentUser = userStore.user;

  const cancelEditing = () => {
    setSelectedEditingItem(undefined);
  };

  const openChartHandler = async (
    symbol: string,
    dateCreated: string,
  ): Promise<void> => {
    setPastYearData([]);
    setLoadingChart(true);
    setShowYearlyChart(true);
    setErrorMessage('');

    try {
      const response: FormattedDailyData[] =
        await stockService.getPastYearForChart(symbol);

      await alertStore.loadTriggredNotifications(symbol);
      setPastYearData(response.reverse());
      setChartSymbol(symbol);
      setNotificationTriggers(alertStore.userTriggers);
      setAlertDateCreated(dateCreated);
    } catch (error: any) {
      setErrorMessage(error.message);
      setShowYearlyChart(false);
    }
    setLoadingChart(false);
  };

  const isEditingValidItem = () => {
    return (
      selectedEditingItem &&
      alertStore.userAlerts.find((x) => x.id === selectedEditingItem.id)
    );
  };

  return (
    <>
      {currentUser?.subscriptionId && (
        <Grid textAlign={isWideScreen() ? 'left' : 'center'}>
          <Grid.Column
            className="center aligned"
            computer={10}
            largeScreen={8}
            tablet={10}
            mobile={14}
            style={{ paddingBottom: 10 }}
          >
            <VerifyPhoneNumber />
            <ErrorDisplay message={errorMessage} />
            {isEditingValidItem() && selectedEditingItem && (
              <AlertForm
                alertLimitReached={alertLimitReached}
                isLoading={alertStore.isLoading}
                data-testid="editExistingAlertFrom"
                key={`editAlertForm-${selectedEditingItem.id}`}
                cancelButtonLabel="Cancel"
                onSaveClick={async (savedAlert) => {
                  await alertStore.updateAlert(savedAlert);
                  setSelectedEditingItem(undefined);
                }}
                inputAlert={selectedEditingItem}
                onCancelClick={() => {
                  cancelEditing();
                }}
              />
            )}
            <Grid.Row centered={true}>
              <Grid.Column>
                <CompactQuestionnaire />
                {showYearlyChart && (
                  <Grid.Column
                    style={{ marginBottom: 30 }}
                    largeScreen={10}
                    computer={10}
                    mobile={12}
                    tablet={9}
                  >
                    {loadingChart && <Loader active={loadingChart} />}
                    {!loadingChart && (
                      <>
                        {isNotMinimumReadableChartWidth() && (
                          <Message info>
                            <p>Rotate screen horizontally to view chart.</p>
                          </Message>
                        )}
                        <LineChart
                          dailyData={pastYearData}
                          symbol={chartSymbol}
                          triggers={notificationTriggers}
                          dateCreated={alertDateCreated}
                        />
                        <Segment
                          style={{
                            backgroundColor: '#002f37',
                            color: 'white',
                            width: '100%',
                            border: `solid 1px #E0E0E0`,
                            padding: 15,
                            fontWeight: 'bold',
                            fontSize: '1.2em',
                          }}
                        >
                          The above chart shows all alerts that have been sent
                          to this account since the beginning date of
                          monitoring.
                        </Segment>
                        <Button
                          style={{ marginRight: '1em' }}
                          onClick={() => setShowYearlyChart(false)}
                        >
                          Close
                        </Button>
                      </>
                    )}
                  </Grid.Column>
                )}
                {alertStore.userAlerts.length > 0 && (
                  <SavedAlertsList
                    data-testid="savedAlertsList"
                    alerts={alertStore.userAlerts
                      .slice()
                      .sort((a, b) => a.symbol.localeCompare(b.symbol))}
                    onChartClick={async (
                      symbol: string,
                      dateCreated: string,
                    ) => {
                      openChartHandler(symbol, dateCreated);
                    }}
                    onEditClick={(alert: IAlert) => {
                      cancelEditing();
                      setSelectedEditingItem(alert);
                    }}
                    hideChartButton={showYearlyChart}
                  />
                )}

                <AddToHomeScreen
                  skipFirstVisit={false}
                  cookie={{ name: 'risk-alert', expireDays: 30 }}
                  styles={{
                    body: {
                      backgroundColor: '#dee2e6',
                      color: '#E95825',
                    },
                    heading: { color: '#E95825', fontSize: '20px' },
                  }}
                  translate={{
                    headline: 'Add Risk Alert to your home screen.',
                    safariTapShare: 'Tap Share',
                    safariAddHomeScreen: 'Tap "Add to Home Screen"',
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      )}

      {!currentUser?.subscriptionId && (
        <Grid.Column width={14} style={{ margin: '0 auto' }}>
          <CreateSubscription />
        </Grid.Column>
      )}

      {alertStore.userAlerts.length === 0 && <Redirect to="/questionnaire" />}
    </>
  );
};

export default observer(MonitoredStocks);
