import { UsernameAttributes } from 'aws-amplify-react';
import React from 'react';
import { ILoginProps } from '../../domain/interfaces/Login/ILoginProps';
import RiskAuthenticator from '../Authenticator/RiskAuthenticator';
import SignUpConfig from '../Authenticator/SignUpConfig/SignUpConfig';

export const Login: React.FC<ILoginProps> = (props) => {
  return (
    <>
      <RiskAuthenticator
        {...props}
        usernameAttributes={UsernameAttributes.EMAIL}
        signUpConfig={SignUpConfig}
        onAuthStateChanged={props.onAuthStateChanged}
        authState={props.authState}
      />
    </>
  );
};
