import React, { useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { IAuthProps } from '../../domain/interfaces/Authentication/IAuthProps';
import { UserInitializer } from '../UserInitializer/UserInitializer';
import authStorage from '../../services/AuthenticationStorage';
import LoginHeader from '../Layout/LoginHeader';
import { AuthState } from '@aws-amplify/ui-components';
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
} from '@aws-amplify/ui-react';
import SignUpConfig from './SignUpConfig/SignUpConfig';
import { shouldStackRememberMe } from '../../services/Layout';
import { useQuery } from './UseQuery';

const RiskAuthenticator: React.FC<IAuthProps> = (props: IAuthProps) => {
  const signedIn = 'signedin';
  const signIn = 'signin';

  const signup = useQuery()?.get('signup') === 'true';

  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem('remember-me') === 'true',
  );

  const onRememberMeChange = (checked: boolean | undefined) => {
    const isChecked = checked || false;
    setRememberMe(isChecked);

    authStorage.setRememberMe(isChecked);
    localStorage.setItem('remember-me', isChecked.toString());
  };

  const rememberMeCheckbox = () => {
    if (props.authState.authState !== signIn) {
      return null;
    }

    return (
      <div
        style={{
          margin: 'auto',
          alignSelf: 'center',
          width: shouldStackRememberMe() ? '403px' : '218px',
          paddingLeft: 40,
          height: 0,
        }}
      >
        <div
          style={{
            position: 'relative',
            zIndex: 1,
            top: shouldStackRememberMe() ? '375px' : '362px',
            display: 'inline-block',
            color: '#dee4e7',
          }}
        >
          <Checkbox
            label={
              <label
                style={{
                  color: '#dee4e7',
                  fontSize: '1.125rem',
                  fontFamily: '"Helvetica Neue", sans-serif',
                }}
              >
                Remember me
              </label>
            }
            onChange={(_, data) => onRememberMeChange(data.checked)}
            checked={rememberMe}
            data-testid="remember me"
            style={{
              color: '#dee4e7',
              fontSize: '12px',
              fontFamily: '"Helvetica Neue", sans-serif',
            }}
            color={'#dee4e7'}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {props.authState.authState !== signedIn && (
        <>
          <LoginHeader />
          <div>
            {rememberMeCheckbox()}
            <AmplifyAuthenticator
              handleAuthStateChange={props.onAuthStateChanged}
              {...props}
              usernameAlias="email"
              initialAuthState={signup ? AuthState.SignUp : AuthState.SignIn}
            >
              <AmplifySignUp
                slot="sign-up"
                formFields={SignUpConfig.signUpFields}
                usernameAlias="email"
              />
              <AmplifySignIn slot="sign-in" usernameAlias="email" />
            </AmplifyAuthenticator>
          </div>
        </>
      )}
      {props.authState.authState === signedIn && (
        <>
          <UserInitializer />
        </>
      )}
    </>
  );
};

export default RiskAuthenticator;
