// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const FormatPrice = (num: any): string => {
  if (num < 0) {
    num = num * -1;
    return `($${('' + num).replace(
      /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
      function (m, s1, s2) {
        return s2 || s1 + ',';
      },
    )})`;
  } else {
    return ('$' + num).replace(
      /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
      function (m, s1, s2) {
        return s2 || s1 + ',';
      },
    );
  }
};
