import React from 'react';
import { Confirm, Header } from 'semantic-ui-react';

export interface ConfirmCancelSubscriptionProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmCancelSubscription: React.FC<ConfirmCancelSubscriptionProps> =
  ({ isOpen, onCancel, onConfirm }) => {
    const cancelHandler = () => {
      onCancel();
    };

    const confirmHandler = () => {
      onConfirm();
    };

    return (
      <Confirm
        open={isOpen}
        onCancel={cancelHandler}
        onConfirm={confirmHandler}
        confirmButton={'Yes'}
        cancelButton={'No'}
        content={
          <>
            <Header>Are you sure you want to cancel?</Header>
          </>
        }
      />
    );
  };
