/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { SearchResultRow } from './SearchResultRow';
import { SearchForStock } from '../Functions/SearchForStock';
import { useDebounce } from '../../Hooks/useDebounce';
import { Search } from 'semantic-ui-react';

interface ISymbolSearchProps {
  isDisabled?: boolean;

  onSelected: (selectedSearchResult: any) => void;
}

export const SymbolSearch: React.FC<ISymbolSearchProps> = (
  props: ISymbolSearchProps,
) => {
  const { isDisabled, onSelected } = props;

  const [searchTerm, setSearchTerm] = useState<string | undefined>('');

  const [loading, setIsLoading] = useState(false);

  const [searchResults, setSearchResults] = useState<any[]>([]);

  useDebounce(
    async () => {
      if (searchTerm) {
        setIsLoading(true);

        try {
          const searchResult = await SearchForStock(searchTerm);

          if (searchResult) {
            const mappedToDescription = searchResult.map((item: any) => {
              return {
                ...item,

                description: item.name,

                id: 1 - Math.random(),

                title: item.symbol,

                price: '',
              };
            });

            setSearchResults(mappedToDescription);

            return;
          }
        } catch (ex) {
          console.error(ex);
        } finally {
          setIsLoading(false);
        }
      }

      setSearchResults([]);
    },

    500,

    [searchTerm],
  );

  return (
    <Search
      disabled={isDisabled || false}
      style={{ borderRadius: '0px' }}
      input={{
        input: {
          'data-testid': 'searchInput',
        },

        fluid: true,

        style: { borderRadius: '0px' },
      }}
      fluid
      aligned={'left'}
      loading={loading}
      onResultSelect={(e, data) => {
        onSelected(data.result);

        setSearchTerm('');
      }}
      onSearchChange={(e, data) => {
        setSearchTerm(data.value);
      }}
      showNoResults={false}
      resultRenderer={SearchResultRow}
      results={searchResults}
      value={searchTerm}
    />
  );
};
