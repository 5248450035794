import { Auth } from 'aws-amplify';
import React from 'react';
import IdleTimer from 'react-idle-timer';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export interface IAuthenticatedRouteProps extends RouteProps {
  authState: string;
}

export const AuthenticatedRoute: React.FC<IAuthenticatedRouteProps> = (
  props: IAuthenticatedRouteProps,
) => {
  const onIdle = async () => {
    if (localStorage.getItem('remember-me') !== 'true') {
      await Auth.signOut();
    }
  };

  if (props.authState === 'signedin') {
    return (
      <>
        <IdleTimer onIdle={onIdle} timeout={1000 * 60 * 30} startOnMount />
        <Route {...props} />
      </>
    );
  }
  return <Redirect to="/login" />;
};
