import { PaymentMethod } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { Dimmer, Divider, Grid, Header, Loader } from 'semantic-ui-react';
import { useAlertServiceContext } from '../RiskAlertContext/RiskAlertContext';
import { DefaultSignupForm } from '../Subscriptions/DefaultSignupForm';
import gtag from 'ga-gtag';
export const CreateSubscription: React.FC = () => {
  const { userStore } = useAlertServiceContext();
  const [isLoading, setIsLoading] = useState(false);

  const save = async (paymentMethod: PaymentMethod, promotionCode: string) => {
    setIsLoading(true);
    try {
      if (userStore.user?.customerId === undefined) {
        await userStore.createCustomer();
      }

      if (userStore.user?.customerId) {
        await userStore.createSubscription(
          paymentMethod.id,
          userStore.user.customerId,
          promotionCode,
        );
        gtag('event', 'conversion', {
          send_to: 'AW-10845770200/84pgCIP-q5QDENij1bMo',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dimmer active={isLoading}>
        <Loader data-testid="saveLoader" content="Processing Payment" />
      </Dimmer>
      <Grid style={{ flex: 1 }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Divider />
            <Header>
              Please create your subscription to access your Risk Alerts
            </Header>
            <Divider />
            <DefaultSignupForm
              onSaveHandler={async (
                paymentMethod: PaymentMethod,
                promotionCode: string,
              ) => {
                await save(paymentMethod, promotionCode);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
