import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { useAlertServiceContext } from '../RiskAlertContext/RiskAlertContext';

export const UserInitializer: React.FC = observer(() => {
  const { alertStore, userStore } = useAlertServiceContext();

  const [isInitializing, setIsInitializing] = useState(true);
  const [isActiveSubscription, setIsActiveSubscription] = useState(false);

  const checkSubscription = async () => {
    setIsActiveSubscription(await userStore.checkSubscriptionStatus());
  };

  useEffect(() => {
    const loadUserAlertsPromise = alertStore.loadUserAlerts();
    const loadUserPromise = userStore.loadUser();
    Promise.all([loadUserAlertsPromise, loadUserPromise])
      .then(() => checkSubscription())
      .finally(() => {
        setIsInitializing(false);
      });
  }, []);

  const redirect = () => {
    if (!isActiveSubscription || !userStore.user?.subscriptionId) {
      return <Redirect to="/collect-initial-payment-info" />;
    }
    if (alertStore.userAlerts.length > 0) {
      return <Redirect to="/dashboard" />;
    }

    return <Redirect to="questionnaire" />;
  };

  return isInitializing ? <Loader active /> : redirect();
});
