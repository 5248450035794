import { Auth } from 'aws-amplify';

export default async (options: RequestInit): Promise<RequestInit> => {
  const session = await Auth.currentSession();

  options.headers = new Headers({
    Authorization: session.getIdToken().getJwtToken(),
  });

  return options;
};
