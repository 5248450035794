import { useEffect, useRef } from 'react';

export function useDebounce(
  callback: () => Promise<void>,
  timeout: number,
  deps: any[],
): void {
  const timeoutId: any = useRef();

  useEffect(() => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(callback, timeout);

    return () => clearTimeout(timeoutId.current);
  }, deps);
}
