import { ChangeResult } from '../Domain/Interfaces/ChangeResult';

export const calculateChange = (
  currentPrice: number,

  previousClose: number,
): ChangeResult => {
  if (currentPrice > previousClose) {
    return { status: 'rise', alert: true };
  } else if (currentPrice < previousClose) {
    return { status: 'fall', alert: true };
  } else {
    return { status: 'no change', alert: false };
  }
};
