import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import ErrorDisplay from '../Messaging/ErrorDisplay';
import { PaymentMethod } from '@stripe/stripe-js';
import { Form } from 'semantic-ui-react';
import { useAlertServiceContext } from '../RiskAlertContext/RiskAlertContext';
import toast, { Toaster } from 'react-hot-toast';

const useOptions = () => {
  return {
    style: {
      base: {
        fontSize: '14px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

export interface CreditCardDetailsProps {
  onPaymentMethodChange(paymentMethod: PaymentMethod): void;
  zipCode: string;
  disableVerify: boolean;
}

const CreditCardDetails = forwardRef((props: CreditCardDetailsProps, ref) => {
  const { onPaymentMethodChange, zipCode, disableVerify } = props;
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [paymentDetails, setPaymentDetails] = useState({});
  const validZipCode = zipCode.toString().length === 5;

  useImperativeHandle(ref, () => {
    clear;
  });

  const { userStore } = useAlertServiceContext();

  const onVerifyClick = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement) {
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            postal_code: zipCode.toString(),
          },
        },
      });

      if (payload.paymentMethod) {
        payload;
        setPaymentDetails(payload.paymentMethod.type);
        onPaymentMethodChange(payload.paymentMethod);

        toast.success('Card has been verified');
      } else {
        toast.error('Card could not be verified');
      }
    }
  };

  const getColor = () => {
    return paymentDetails ? 'green' : undefined;
  };

  const clear = () => {
    if (!elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    elements.getElement(CardNumberElement)?.clear();
    elements.getElement(CardExpiryElement)?.clear();
    elements.getElement(CardCvcElement)?.clear();
  };

  const style = { marginTop: 15 };

  const placeholderCardNumber = !!userStore.user?.paymentMethodLastFour
    ? `XXXX XXXX XXXX ${userStore.user.paymentMethodLastFour}`
    : '1234 1234 1234 1234';

  return (
    <>
      <Toaster />
      <Form.Field style={style}>
        <label>Card Number</label>
        <CardNumberElement
          options={{ ...options, placeholder: placeholderCardNumber }}
        />
      </Form.Field>
      <Form.Field style={style}>
        <label>Expiration date</label>
        <CardExpiryElement options={options} />
      </Form.Field>
      <Form.Field style={style}>
        <label>CVC</label>
        <CardCvcElement options={options} />
      </Form.Field>
      {!validZipCode && <ErrorDisplay message={'Please enter a valid ZIP'} />}
      <Form.Button
        disabled={disableVerify || !validZipCode}
        onClick={onVerifyClick}
        color={getColor()}
        style={style}
      >
        Verify
      </Form.Button>
    </>
  );
});

export default CreditCardDetails;
