import { detach, flow, getEnv, Instance, types } from 'mobx-state-tree';
import { IAlertService } from '../services/Alert/IAlertService';

export const Alert = types.model('Alert', {
  id: types.string,
  market: types.string,
  name: types.string,
  symbol: types.string,
  emailAlerts: types.boolean,
  textAlerts: types.boolean,
  upswingPercent: types.maybe(types.number),
  downswingPercent: types.maybe(types.number),
  dateCreated: types.maybe(types.string),
});

export const TriggeredNotification = types.model('TriggeredNotification', {
  alertType: types.string,
  date: types.string,
});

export const AlertStore = types
  .model('AlertStore', {
    userAlerts: types.array(Alert),
    userTriggers: types.array(TriggeredNotification),
    isLoading: types.boolean,
  })
  .actions((self) => {
    const alertService: IAlertService = getEnv(self).alertService;

    const wrapLoadingDuringAction = flow(function* (serviceAction: any) {
      self.isLoading = true;
      try {
        yield serviceAction();
      } catch (err) {
        console.error(err);
      }
      self.isLoading = false;
    });

    return {
      loadUserAlerts: flow(function* () {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const alerts = yield alertService.getAlerts();
            self.userAlerts.clear();
            self.userAlerts.push(...alerts);
          }),
        );
      }),
      loadTriggredNotifications: flow(function* (symbol: string) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const notifications =
              yield alertService.getTriggeredNotificationForSymbol(symbol);
            self.userTriggers.clear();
            self.userTriggers.push(...notifications);
          }),
        );
      }),
      addAlert: flow(function* (alert: IAlert) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            yield alertService.saveAlert(alert);
            self.userAlerts.push(alert);
          }),
        );
      }),
      updateAlert: flow(function* (alert: IAlert) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const idx = self.userAlerts.findIndex((x) => x.id == alert.id);
            if (idx >= -1) {
              yield alertService.saveAlert(alert);
              Object.assign(self.userAlerts[idx], alert);
            }
          }),
        );
      }),
      removeAlert: flow(function* (alertToRemove: IAlert) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const idx = self.userAlerts.indexOf(alertToRemove);
            yield alertService.deleteAlert(self.userAlerts[idx]);
            detach(self.userAlerts[idx]);
          }),
        );
      }),
      clearAlerts: () => {
        self.userAlerts.clear();
      },
    };
  });

export interface IAlert extends Instance<typeof Alert> {}
export interface IAlertStore extends Instance<typeof AlertStore> {}
