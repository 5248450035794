export class AuthenticationStorage {
  _sessionStorage: Storage;
  _localStorage: Storage;
  _storage: Storage;

  constructor(sessionStorage: Storage, localStorage: Storage) {
    this._sessionStorage = sessionStorage;
    this._localStorage = localStorage;
    this._storage = this._localStorage.getItem('remember-me')
      ? this._localStorage
      : this._sessionStorage;
  }

  setItem(key: string, value: string): void {
    return this._storage.setItem(key, value);
  }

  getItem(key: string): string | null {
    return this._storage.getItem(key);
  }

  removeItem(key: string): void {
    this._storage.removeItem(key);
  }

  clear(): void {
    this._storage.clear();
  }

  setRememberMe(rememberMe: boolean): void {
    if (rememberMe) {
      this._storage = this._localStorage;
    } else {
      this._storage = this._sessionStorage;
    }
  }
}

const _authenticationStorage = new AuthenticationStorage(
  window.sessionStorage,
  window.localStorage,
);

export default _authenticationStorage;
