import React, { useState } from 'react';
import { Button, Form, Grid, Header, Input, Popup } from 'semantic-ui-react';
import { changePassword } from '../../services/User/UserProfileService';
import ErrorDisplay from '../Messaging/ErrorDisplay';
import SuccessDisplay from '../Messaging/SuccessDisplay';

const ChangePassword: React.FunctionComponent = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const onSaveClick = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    try {
      await changePassword(currentPassword, newPassword);
      setSuccessMessage('Your password has been changed!');
    } catch (error: any) {
      if (
        error.code === 'InvalidParameterException' ||
        error.code === 'InvalidPasswordException'
      ) {
        setErrorMessage(
          'Password must be 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number.',
        );
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <>
      <Header>Change Password</Header>
      <Form>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>Current Password</label>
                <Input
                  placeholder="Current Password"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  type="password"
                  fluid
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>New Password</label>
                <Popup
                  trigger={
                    <Input
                      placeholder="New Password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      fluid
                      type="password"
                    />
                  }
                  content="Minimum password length 8 characters. Password must have  uppercase letters, lowercase letters, special characters, and numbers."
                  basic
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button onClick={onSaveClick} primary style={{ width: 153 }}>
                Change Password
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ErrorDisplay message={errorMessage} />
              <SuccessDisplay message={successMessage} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
};

export default ChangePassword;
