import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'semantic-ui-less/semantic.less';
import { Button, Divider, Grid, Header, Icon } from 'semantic-ui-react';
import { v4 } from 'uuid';
import { ISearchedItem } from '../../services/Stock/IStockService';
import { IAlert } from '../../stores/AlertStore';
import { useAlertServiceContext } from '../RiskAlertContext/RiskAlertContext';
import { AlertForm } from './AlertForm';
import { SavedAlertsList } from './SavedAlertsList';
import { SymbolSearch } from './SymbolSearch';

export const Questionaire: React.FC = () => {
  const { alertStore, stockService } = useAlertServiceContext();
  const history = useHistory();
  const alertLimitReached = alertStore.userAlerts.length >= 50;

  const [selectedNewItem, setSelectedNewItem] = useState<
    ISearchedItem | undefined
  >();

  const [selectedEditingItem, setSelectedEditingItem] = useState<
    IAlert | undefined
  >();

  const isEditingValidItem = () => {
    return (
      selectedEditingItem &&
      alertStore.userAlerts.find((x) => x.id === selectedEditingItem.id)
    );
  };

  const cancelEditing = () => {
    setSelectedEditingItem(undefined);
    setSelectedNewItem(undefined);
  };

  return (
    <>
      <Grid stackable style={{ margin: '0 auto 0 auto', maxWidth: '500px' }}>
        <Grid.Column verticalAlign="middle">
          <Grid.Row>
            <Header>
              Welcome to Risk Alert. Let&apos;s set up your alerts
            </Header>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row textAlign="center" verticalAlign="middle">
            <Header size="large">
              Enter your&nbsp;
              {alertStore.userAlerts.length > 0 ? 'next' : 'first'}
              &nbsp;symbol or enter name
            </Header>
            <SymbolSearch
              onSearch={async (x) => {
                return await stockService.search(x);
              }}
              onSelected={(selectedItem: ISearchedItem) => {
                setSelectedNewItem(selectedItem);
              }}
            />
          </Grid.Row>
          {alertStore.userAlerts.length > 0 && (
            <>
              <Divider hidden />
              <Grid.Row>
                <Header>
                  I'm done, take me to the dashboard
                  <Button
                    primary
                    icon
                    style={{ padding: 5, marginLeft: 10 }}
                    onClick={() => history.push('/dashboard')}
                  >
                    <Icon name="arrow right" />
                  </Button>
                </Header>
              </Grid.Row>
            </>
          )}
          <Divider hidden />
          {(selectedNewItem && (
            <Grid.Row>
              <AlertForm
                alertLimitReached={alertLimitReached}
                isLoading={alertStore.isLoading}
                data-testid="editNewAlertFrom"
                cancelButtonLabel="Cancel"
                onSaveClick={async (savedAlert) => {
                  await alertStore.addAlert(savedAlert);
                  cancelEditing();
                }}
                inputAlert={{
                  market: selectedNewItem.market,
                  id: v4(),
                  emailAlerts: true,
                  name: selectedNewItem.name,
                  symbol: selectedNewItem.symbol,
                  downswingPercent: 0,
                  textAlerts: true,
                  upswingPercent: 0,
                  dateCreated: new Date().toUTCString(),
                }}
                onCancelClick={() => {
                  cancelEditing();
                }}
              />
            </Grid.Row>
          )) ||
            (isEditingValidItem() && selectedEditingItem && (
              <AlertForm
                alertLimitReached={alertLimitReached}
                isLoading={alertStore.isLoading}
                data-testid="editExistingAlertFrom"
                key={`editAlertForm-${selectedEditingItem.id}`}
                cancelButtonLabel="Cancel"
                onSaveClick={async (savedAlert) => {
                  await alertStore.updateAlert(savedAlert);
                  setSelectedEditingItem(undefined);
                }}
                inputAlert={selectedEditingItem}
                onCancelClick={cancelEditing}
              />
            ))}

          <Grid.Row>
            <Divider />
            {alertStore.userAlerts.length > 0 && (
              <SavedAlertsList
                onChartClick={async () => {
                  Promise.resolve();
                }}
                hideChartButton={true}
                data-testid="savedAlertsList"
                alerts={alertStore.userAlerts}
                onEditClick={(alert: IAlert) => {
                  cancelEditing();
                  setSelectedEditingItem(alert);
                }}
              />
            )}
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default observer(Questionaire);
