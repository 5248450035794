import { PaymentMethod } from '@stripe/stripe-js';
import React, { useRef, useState } from 'react';
import { Grid, Form, Checkbox, Icon, Header, Button } from 'semantic-ui-react';
import { sendAccountSettingsUpdatedEmailAsync } from '../../services/Email/AccountUpdatedEmailSender';
import CreditCardDetails from '../CreditCardDetails/CreditCardDetails';
import ErrorDisplay from '../Messaging/ErrorDisplay';
import { useAlertServiceContext } from '../RiskAlertContext/RiskAlertContext';
import { ConfirmCancelSubscription } from './ConfirmCancelSubscription';

export interface SubscriptionSummaryProps {
  onSaveHandler: (paymentMethod: PaymentMethod) => void;
}

export const SubscriptionSummary: React.FC<SubscriptionSummaryProps> = ({
  onSaveHandler,
}) => {
  const [givenName, setGivenName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [isEditingPayment, setIsEditingPayment] = useState(false);
  const { userStore } = useAlertServiceContext();
  const creditCardDetailsRef = useRef<any>();

  const onCancelSubscriptionConfirm = async () => {
    await userStore.cancelSubscription();

    await sendAccountSettingsUpdatedEmailAsync(
      'Your RiskAlert subscription has been canceled.',
    );

    setIsCancelling(false);
  };

  const onCancel = () => {
    setIsCancelling(false);
  };

  const savePayment = async () => {
    await onSaveHandler(paymentMethod!);
    setGivenName('');
    setStreetAddress('');
    setCity('');
    setZipCode('');
    setState('');
    setAcceptedTerms(false);
    setIsVerified(false);
    setPaymentMethod(undefined);
    setIsEditingPayment(false);
    creditCardDetailsRef?.current?.clear();
  };

  return (
    <>
      <Header>Subscription Management</Header>
      {isCancelling && (
        <ConfirmCancelSubscription
          isOpen={isCancelling}
          onConfirm={onCancelSubscriptionConfirm}
          onCancel={onCancel}
        />
      )}
      {isEditingPayment && (
        <>
          <h4>Please Enter Your Details</h4>
          <Grid width={12} style={{ flex: 1, maxWidth: 1200 }}>
            <Grid.Row>
              <Grid.Column>
                <Form>
                  <div>
                    <ErrorDisplay message={''} />
                    <Grid stackable columns="equal">
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Input
                            name="name"
                            label="Name"
                            placeholder="Name"
                            onChange={(e) => {
                              setGivenName(e.target.value);
                            }}
                            value={givenName}
                            required
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Input
                            name="streetAddress"
                            label="Address"
                            placeholder="Address"
                            onChange={(e) => {
                              setStreetAddress(e.target.value);
                            }}
                            value={streetAddress}
                            required
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Input
                            name="city"
                            label="City"
                            placeholder="City"
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}
                            value={city}
                            required
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Input
                            name="state"
                            label="State"
                            placeholder="State"
                            onChange={(e) => {
                              setState(e.target.value);
                            }}
                            value={state}
                            required
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Input
                            name="zipCode"
                            label="Zip Code"
                            placeholder="Zip Code"
                            onChange={(e) => {
                              setZipCode(e.target.value);
                            }}
                            value={zipCode}
                            required
                            type="number"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                  <div>
                    <>
                      <CreditCardDetails
                        zipCode={zipCode}
                        onPaymentMethodChange={(
                          paymentMethod: PaymentMethod,
                        ) => {
                          setIsVerified(true);
                          setPaymentMethod(paymentMethod);
                        }}
                        disableVerify={isVerified}
                        ref={creditCardDetailsRef}
                      />
                    </>

                    <br />
                    <Checkbox
                      data-testid="userAgreement"
                      label="By checking this box I agree my card will be charged."
                      onChange={() => {
                        setAcceptedTerms(!acceptedTerms);
                      }}
                      checked={acceptedTerms}
                    />
                  </div>
                  <p>&nbsp;</p>
                  <Form.Button
                    style={{ marginBottom: '.5rem' }}
                    primary
                    onClick={savePayment}
                    labelPosition="right"
                    data-testid="save"
                    floated="right"
                    fluid
                    icon
                    disabled={!isVerified || !acceptedTerms}
                  >
                    Save
                    <Icon />
                  </Form.Button>
                  <Form.Button
                    style={{ marginBottom: '.5rem' }}
                    labelPosition="right"
                    data-testid="save"
                    floated="right"
                    fluid
                    icon
                    onClick={() => setIsEditingPayment(false)}
                  >
                    Cancel
                  </Form.Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}

      {!isEditingPayment && (
        <Grid style={{ flex: 1, maxWidth: 800 }}>
          <Grid.Row>
            <Grid.Column>
              <Button
                style={{ margin: '.5rem .5rem .5rem 0rem' }}
                onClick={() => {
                  setIsCancelling(true);
                }}
              >
                Cancel Subscription
              </Button>
              <Button
                style={{ margin: '.5rem .5rem .5rem 0rem' }}
                onClick={() => {
                  setIsEditingPayment(true);
                }}
              >
                Edit Credit Card Details
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
};
