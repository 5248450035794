export const shouldStackRememberMe = (): boolean => {
  return window.innerWidth > 671;
};

export const isNotMinimumReadableChartWidth = (): boolean => {
  return window.innerWidth < 555;
};

export const isWideScreen = (): boolean => {
  return window.innerWidth > 704;
};

export const isIpadScreen = (): boolean => {
  return window.innerWidth > 800;
};
