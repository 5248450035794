import React from 'react';
import { Grid } from 'semantic-ui-react';
import { ResultsProps } from '../Domain/Interfaces/ResultsProps';
import { ChartContainer } from './ChartContainer';
import { ResultsHeader } from './ResultsHeader';
import { ResultsSummary } from './ResultsSummary';

export const Results: React.FC<ResultsProps> = ({
  engineResults,
  canSeeDetails,
  detailsClickHandler,
  resetClickHandler,
  editClickHandler,
  selectedSymbol,
}) => {
  if (engineResults) {
    return (
      <Grid
        centered
        textAlign={'left'}
        style={{
          height: '100%',
          marginBottom: '40px',
          width: 'auto',
        }}
      >
        <ResultsHeader
          canSeeDetails={false}
          selectedSymbol={selectedSymbol}
          engineResults={engineResults}
          resetClickHandler={resetClickHandler}
          editClickHandler={editClickHandler}
        />

        <ChartContainer engineResults={engineResults} />
        <ResultsSummary
          engineResults={engineResults}
          canSeeDetails={canSeeDetails}
          onButtonClick={detailsClickHandler}
        />
      </Grid>
    );
  } else {
    return <></>;
  }
};
