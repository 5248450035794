import { Auth } from 'aws-amplify';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import { useAlertServiceContext } from '../RiskAlertContext/RiskAlertContext';

const NavigationMenuForMobile: React.FunctionComponent = () => {
  const { alertStore } = useAlertServiceContext();
  const location = useLocation();
  const logout = async () => {
    await Auth.signOut();

    alertStore.clearAlerts();
  };

  return (
    <>
      <Menu.Item
        as={Link}
        active={location.pathname === '/monitored-stocks'}
        to="/monitored-stocks"
      >
        Stocks Being Monitored
      </Menu.Item>
      <Menu.Item
        as={Link}
        active={location.pathname === '/compare-alert'}
        to="/compare-alert"
      >
        CompareAlert
      </Menu.Item>
      <Menu.Item
        as={Link}
        active={location.pathname === '/user-profile'}
        to="/user-profile"
      >
        User Profile
      </Menu.Item>
      <Menu.Item as={'a'} target="#" href="https://myriskalert.com/">
        About
      </Menu.Item>
      <Menu.Item as={'a'} target="#" href="https://myriskalert.com/faqs/">
        FAQs
      </Menu.Item>
      <Menu.Item as={'a'} target="#" href="https://myriskalert.com/pricing/">
        Pricing
      </Menu.Item>
      <Menu.Item as={'a'} target="#" href="https://myriskalert.com/contact/">
        Contact
      </Menu.Item>
      <Menu.Item onClick={logout} to="/user-profile">
        <Icon name="sign-out" />
        Sign Out
      </Menu.Item>
    </>
  );
};

export default NavigationMenuForMobile;
