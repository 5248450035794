import { UserConfig } from './Domain/Data/UserConfig';
import { ChangeResult } from './Domain/Interfaces/ChangeResult';
import { EngineResults } from './Domain/Interfaces/EngineResults';
import { FormattedWeeklyData } from './Domain/Interfaces/FormattedWeeklyData';
import { calculateChange } from './Functions/CalculateChange';
import { AlertService } from './Services/AlertService';

export default class Engine {
  // Configs
  public UserConfig: UserConfig;
  private _alertService: AlertService;
  private _formattedData: FormattedWeeklyData[];
  private _currentPrice: number;
  private _initialCost: number;
  private _gainOrLoss = 0;
  private _gainOrLossFirstTransaction = 0;

  // Tracking
  private _valueWithAlerts = 0;
  private _valueWithoutAlerts = 0;
  private _valueIfSoldOnFirstAlert = 0;

  constructor(
    sharesToBuy: number,
    risePercentage: number,
    fallPercentage: number,
    formattedData: FormattedWeeklyData[],
    startingPrice: number,
    beginningValue: number,
  ) {
    this._formattedData = formattedData;
    this._currentPrice = startingPrice;
    this._initialCost = beginningValue;
    const adjustedSharesToBuy = sharesToBuy;

    this.UserConfig = new UserConfig(
      adjustedSharesToBuy,
      risePercentage,
      fallPercentage,
      startingPrice,
      beginningValue,
    );
    this._alertService = new AlertService(this.UserConfig);
  }

  public runSimulation = (): EngineResults => {
    const dailyData = [...this._formattedData].reverse();
    const firstDay = dailyData.pop();

    if (firstDay) {
      this._alertService.BuyShares(firstDay, true);
    }

    while (dailyData.length > 0) {
      const currentSeries = dailyData.pop();

      if (!currentSeries) {
        continue;
      }

      const change: ChangeResult = calculateChange(
        +currentSeries.splitAdjustedClosePrice,
        this._currentPrice,
      );

      this._currentPrice = +currentSeries.splitAdjustedClosePrice;

      if (change.alert) {
        this._alertService.ProcessChange(change, currentSeries);
      } else {
        this._alertService.CheckMinMax(this._currentPrice);
      }
    }

    this._alertService.CleanupShares(this._currentPrice);

    const transactions = this._alertService.GetTransactionHistory();

    transactions
      .filter((t) => !t.isCleanup)
      .forEach((t) => {
        this._gainOrLoss += t.difference;
      });

    this._gainOrLossFirstTransaction =
      transactions.filter((t) => !t.isCleanup)[0]?.difference || 0;

    const lastTransaction = transactions[transactions.length - 1];
    this._valueWithAlerts += lastTransaction.sellTotal;

    this._valueWithoutAlerts =
      this._currentPrice * this.UserConfig.GetSharesToBuy();

    this._valueIfSoldOnFirstAlert = transactions[0].sellTotal;

    return {
      alertTransactions: transactions,
      currentValue: this._valueWithoutAlerts,
      valueIfSoldOnFirstAlert: this._valueIfSoldOnFirstAlert,
      valueWithAlerts: this._valueWithAlerts,
      valueWithoutAlerts: this._valueWithoutAlerts,
      weeklyInfo: this._formattedData,
      fallTriggerTransaction: this._alertService.GetRiseTransactions(),
      riseTriggerTransactions: this._alertService.GetFallTransactions(),
      sharesToBuy: this.UserConfig.GetSharesToBuy(),
      riseAlert: this.UserConfig.GetRisePercentage(),
      fallAlert: this.UserConfig.GetFallPercentage(),
      gainOrLoss: this._gainOrLoss,
      initialCost: this._initialCost,
      gainOrLossFirstTransaction: this._gainOrLossFirstTransaction,
    };
  };
}
