import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import { IAlert } from '../../stores/AlertStore';
import SavedAlert from './SavedAlert';

interface ISavedAlertsListProps {
  onEditClick: (editingAlert: IAlert) => void;
  onChartClick: (symbol: string, dateCreated: string) => Promise<void>;
  alerts: IAlert[];
  hideChartButton: boolean;
}

export const SavedAlertsList: React.FC<ISavedAlertsListProps> = (
  props: ISavedAlertsListProps,
) => {
  const { alerts, onEditClick, onChartClick, hideChartButton } = props;
  return (
    <>
      <Container>
        <Header size="large">Stocks Monitored Hourly</Header>
        {alerts.map((item: IAlert) => {
          return (
            <SavedAlert
              onEditClick={onEditClick}
              onChartClick={onChartClick}
              alert={item}
              key={`savedAlert-${item.id}`}
              hideChartButton={hideChartButton}
            />
          );
        })}
      </Container>
    </>
  );
};
