import AddTokenToOptions from '../../AddTokenToOptions';
import { IEmailRequest } from './EmailRequest';

export const sendEmailAsync = async (
  emailRequest: IEmailRequest,
): Promise<void> => {
  await fetch(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/send-email`,
    await AddTokenToOptions({
      method: 'post',
      mode: 'cors',
      body: JSON.stringify({
        emailAddress: emailRequest.emailAddress,
        subject: emailRequest.subject,
        message: emailRequest.message,
      }),
    }),
  );
};
