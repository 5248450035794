import { FormattedWeeklyData } from '../Domain/Interfaces/FormattedWeeklyData';
import {
  DailyInfoResponse,
  StockData,
} from '../Domain/Interfaces/DailyInfoResponse';

const ticksInADay = 1000 * 3600 * 24;

export const FormatData = (
  rawData: DailyInfoResponse,
  startDate: Date = new Date('1-2-2020'),
): FormattedWeeklyData[] => {
  const formattedData: FormattedWeeklyData[] = [];

  const dailySeries = rawData.data;

  const initialValue: { [key: string]: StockData } = {};
  const dates = dailySeries.reduce((prev, current) => {
    prev[current.date.toISOString().split('T')[0]] = current;

    return prev;
  }, initialValue);

  let stockData = dates[yearMonthDay(startDate.toString())];
  let dayCount = 1;
  while (!!stockData) {
    stockData =
      dates[
        new Date(startDate.getTime() + ticksInADay * dayCount)
          .toISOString()
          .split('T')[0]
      ];
    dayCount++;
  }

  const today = new Date();
  const ticksFromStartDate = today.getTime() - startDate.getTime();

  for (let i = 0; i <= ticksFromStartDate; i += ticksInADay) {
    const dateIndex = new Date(startDate.getTime() + i)
      .toISOString()
      .split('T')[0];

    if (!dates[dateIndex]) {
      continue;
    }
    formattedData.push({
      closePrice: dates[dateIndex].close.toString(),
      splitAdjustedClosePrice: dates[dateIndex].splitAdjustedClose.toString(),
      date: monthDayYear(dateIndex),
      splitAdjustment: dates[dateIndex].splitAdjustment,
    });
  }
  return formattedData;
};

const yearMonthDay = (date: string) => {
  const d = new Date(date);
  let month = '' + (d.getUTCMonth() + 1);
  let day = '' + d.getUTCDate();
  const year = d.getUTCFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

const monthDayYear = (date: string) => {
  const d = new Date(date);
  let month = '' + (d.getUTCMonth() + 1);
  let day = '' + d.getUTCDate();
  const year = d.getUTCFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('-');
};
